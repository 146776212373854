import services from '@superpagos/mf-services';
import {
  CUSTOMER_CREATE_PAYMENT,
  CUSTOMER_FETCH_PAYMENT,
  FETCH_PAYMENTS,
  GENERATE_PAYMENT_RESOURCE,
  GENERATE_PAYMENT_RESOURCE_CASH_FACTORY,
  GENERATE_PAYMENT_RESOURCE_NO_AUTH,
  GET_RESUME_NO_AUTH,
  GET_PRODUCTS_NO_AUTH,
  PAYMENT_GET_FEATURES_NO_AUTH,
  GET_TRANSACTIONS,
  PAYMENT_CREATE,
  PAYMENT_GET_FEATURES,
  PAYMENT_STATUS,
  DAVIPLATA_GENERATE_OTP,
  PAYMENT_CONFIRM,
  GET_TERMS_BNPL,
  ACCEPT_TERMS_BNPL,
  GET_SESSION_INTENTION,
  SET_CHAGE_ACCOUNT,
  SET_PROVIDE_AUTHENTICATION,
  GET_PRODUCTS_QR_NO_AUTH,
  GET_RESUME_QR_NO_AUTH,
  GENERATE_PAYMENT_QR_RESOURCE_NO_AUTH,
  PAYMENT_GET_FEATURES_QR_NO_AUTH,
  PAYMENT_GET_FEATURES_QR,
  SET_CANCEL_TRANSACCION,
  GET_RECEIPT,
  SHARE_WITH_EMAIL,
  SHARE_WITH_WHATSAPP,
  GET_PUBLIC_TRANSACTIONS_RECEIPT,
  GET_BNPL_VALIDATION_QUOTA,
  REQUEST_QR_INTEROPERABLE_FORM
} from '../types/actions.type';

import {
  SET_LOAN_APPLICATION_DATA, 
  SET_PRODUCT_RESOURCE,
  SET_STEP_BNPL,
  SET_AMOUNT_APPROVED,
  SET_SELECTED_ACCOUNT,
  SET_VERSION_PDF,
  SET_LOAN_RESUME,
  SET_BNPL_REQUIREMENTS,
  SET_VALID_LESS_LOAN,
  SET_BRAND_PAYLINK
} from "../types/mutations.type";

const state = {
  paymentResource: null,

  // bnpl
  bnplRequirements: {
    brandId: '',
    reference: '',
    authCode : ''
  },
  stepBnpl : Number(localStorage.getItem('stepBnpl')) || 0,
  amountApproved : 0,
  selectedAccound : {},
  isValidLessLoan : false,
  loanResume : {},
  versionPdf: '',
  brandPayLink: {},
};

const getters = {
  paymentResource(state) {
    return state.paymentResource;
  },
  stepBnpl(state) {
    return state.stepBnpl;
  },
  amountApproved(state) {
    return state.amountApproved;
  },
  selectedAccound(state) {
    return state.selectedAccound;
  },
  loanResume(state) {
    return state.loanResume;
  },
  bnplRequirements(state) {
    return state.bnplRequirements;
  },
  isValidLessLoan(state) {
    return state.isValidLessLoan;
  },
  versionPdf(state) {
    return state.versionPdf;
  },
  brandPayLink(state) {
    return state.brandPayLink;
  },
};

const mutations = {
  [SET_PRODUCT_RESOURCE](state, payload) {
    state.paymentResource = payload;
  },
  [SET_STEP_BNPL](state, payload) {
    state.stepBnpl = payload;
  },
  [SET_AMOUNT_APPROVED](state, payload) {
    state.amountApproved = payload;
  },
  [SET_SELECTED_ACCOUNT](state, payload) {
    state.selectedAccound = payload;
  },
  [SET_LOAN_RESUME](state, payload) {
    state.loanResume = payload;
  },
  [SET_BNPL_REQUIREMENTS](state, payload) {
    state.bnplRequirements = payload;
  },
  [SET_VALID_LESS_LOAN](state, payload) {
    state.isValidLessLoan = payload;
  },
  [SET_VERSION_PDF](state, payload) {
    state.versionPdf = payload;
  },
  [SET_BRAND_PAYLINK](state, payload) {
    state.brandPayLink = payload;
  },
};

const actions = {
  [FETCH_PAYMENTS]() {
    return services.ApiService.get('/api/v1/payments/list');
  },
  [PAYMENT_CREATE](context, data) {
    // return services.ApiService.post('/api/v1/payments/create', data);
    return services.ApiServiceRefacilOld.post('/api/v1/payments/create', data);
  },
  async [GENERATE_PAYMENT_RESOURCE]({ commit }, data) {
    try {
      const res = await services.ApiServicePay.post('/payments-auth/resource', data);
      commit(SET_PRODUCT_RESOURCE, res.payload);
      return res;
    } catch (err) {
      throw err;
    }
  },
  async [GENERATE_PAYMENT_RESOURCE_NO_AUTH]({ commit }, data) {
    try {
      const res = await services.ApiServicePay.post('/payments/resource', data);
      commit(SET_PRODUCT_RESOURCE, res.payload);
      return res;
    } catch (err) {
      throw err;
    }
  },
  async [GENERATE_PAYMENT_QR_RESOURCE_NO_AUTH]({ commit }, data) {
    try {
      const res = await services.ApiServicePay.post('/pay-channels/generate-resource', data);
      commit(SET_PRODUCT_RESOURCE, res.payload);
      return res;
    } catch (err) {
      throw err;
    }
  },
  [GENERATE_PAYMENT_RESOURCE_CASH_FACTORY](context, data) {
    return services.ApiServicePay.post(
      '/payments-auth/collection-resource',
      data
    );
  },
  [GET_TRANSACTIONS](context, data) {
    return services.ApiServicePay.post(
      `/payments-auth/get-transactions/${data.moduleId}`,
      data
    );
  },
  [GET_PRODUCTS_NO_AUTH](context, data) {
    return services.ApiServicePay.post(
      `/payments/get-products/${data.reference}`
    );
  },
  async [GET_RESUME_NO_AUTH]({ commit }, data) {
    try {
     const resp = await services.ApiServicePay.post(`/payments/get/${data.reference}`);
     const { brand } = resp;
     commit(SET_BRAND_PAYLINK, brand);
     return resp
    } catch (err) {
      throw err;
    }
  },
  [GET_PRODUCTS_QR_NO_AUTH](context, data) {
    return services.ApiServicePay.post(
      `/pay-channels/get-products/${data.reference}`
    );
  },
  [GET_RESUME_QR_NO_AUTH](context, data) {
    return services.ApiServicePay.post(`/pay-channels/get/${data.reference}`);
  },
  [PAYMENT_GET_FEATURES_QR_NO_AUTH](_, data) {
    return services.ApiServicePay.get(
      `/pay-channels/get-features/${data.id}/${data.reference}`
    );
  },
  [PAYMENT_GET_FEATURES_QR](_, data) {
    return services.ApiServicePay.get(`/pay-channels/get-features/${data.id}`);
  },
  [PAYMENT_GET_FEATURES](_, data) {
    return services.ApiServicePay.get(`/payments-auth/get-features/${data.id}`);
  },
  [PAYMENT_GET_FEATURES_NO_AUTH](_, data) {
    return services.ApiServicePay.get(
      `/payments/get-features/${data.id}/${data.reference}`
    );
  },
  [CUSTOMER_FETCH_PAYMENT](context, data) {
    return services.ApiService.get('/api/v1/payments/get', data.id);
  },
  [CUSTOMER_CREATE_PAYMENT](context, data) {
    return services.ApiService.post('/api/v1/payments/create/pse/link', data);
  },
  [PAYMENT_STATUS](context, data) {
    // return services.ApiApagarService.post('/transactions/status', data);
    return services.ApiServicePay.get(
      `/payments/get-transaction`, data.reference
    );
  },
  [PAYMENT_CONFIRM]({ state }, { otp }) {
    const { reference } = state.paymentResource
    return services.ApiServicePay.post('/payments/confirm', { reference, otp });
  },
  [GET_TERMS_BNPL](_, data) {
    return services.ApiServicePay.post('/payments/bnpl-terms-retrieve', data);
  },
  [ACCEPT_TERMS_BNPL](_, data) {
    return services.ApiServicePay.post('/payments/bnpl-terms-acceptance', data);
  },
  [GET_SESSION_INTENTION](_, data) {
    return services.ApiServicePay.post('/payments/bnpl-session-intention', data);
  },
  [SET_CHAGE_ACCOUNT](_, data) {
    return services.ApiServicePay.post('/payments/bnpl-change-account', data);
  },
  [SET_PROVIDE_AUTHENTICATION](_, data) {
    return services.ApiServicePay.post('/payments/bnpl-auth-provide-authentication', data);
  },
  [SET_CANCEL_TRANSACCION](_, data) {
    return services.ApiServicePay.post('/payments/bnpl-cancel-transaction', data);
  },
  [GET_RECEIPT](_, id) {
    return services.ApiServicePay.post(
      `/payments-auth/get-transaction-report`,
      { id }
    );
  },
  [SHARE_WITH_EMAIL](_, data) {
    return services.ApiServicePay.post(
      `/payments-auth/send-email-by-id`,
      data
    );
  },
  [SHARE_WITH_WHATSAPP](_, data) {
    return services.ApiServicePay.post(
      `/payments-auth/send-whatsapp-cash-receipt`,
      data
    );
  },
  [GET_PUBLIC_TRANSACTIONS_RECEIPT](_, providerReference) {
    return services.ApiServicePay.post(
      `/payments/get-public-transaction-report`,
      { providerReference }
    );
  },
  [GET_BNPL_VALIDATION_QUOTA](_, data) {
    return services.ApiServicePay.post(
      `/payments/bnpl-prospect-validation-quota`,
      data
    );
  },
  [REQUEST_QR_INTEROPERABLE_FORM](_, data) {
    return services.ApiServicePay.post(
      `/payments-auth/qr-interoperable-form`,
      data
    );
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
