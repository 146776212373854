import services from '@superpagos/mf-services';
import {
	APPLY_LOAN_POLICY,
	GET_LOAN_APPLICATION,
	GET_LOAN_APPLICATION_DATA,
	GET_LOAN_APPLICATION_MERITS,
	GET_LOAN_SECURITY_QUESTIONS,
	GET_PRE_LOAN_DATA,
	GET_PRE_LOAN_ESTIMATE,
	GET_PRE_LOAN_PAYMENT_FRECUENCIES,
	GET_PRE_LOAN_TIME_LIMITS,
	LOAN_SEND_OTP,
	LOAN_VALIDATE_OTP,
	UPDATE_LOAN_APPLICATION,
	UPDATE_LOAN_APPLICATION_DATA,
	VALIDATE_LOAN_SECURITY_QUESTIONS,
	DELETE_LOAN_APPLICATION,
	FINISH_LOAN_REQUEST
} from '../../types/actions.type';
import {
	SET_LOAN_APPLICATION,
	SET_LOAN_APPLICATION_DATA,
	SET_LOAN_APPLICATION_MERITS
} from '../../types/mutations.type';

import { requirementsCategoriesMetadata, numberFromMoneyFormat } from './utils';

function simulateOperation(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

const _PROCESS = 'credit';

const actions = {
	async [GET_LOAN_APPLICATION_MERITS]({ commit }) {
		const p1 = services.ApiServiceRequirement.post(
			'/requirements/getProcessRequirements',
			{ categoryId: 152, productId: 136 }
		);
		const p2 = services.ApiServiceRequirement.post(
			'/requirements/getProcessRequirements',
			{ categoryId: 152, productId: 127 }
		);

		const [preLoanActivationRes, preLoanRes] = await Promise.all([p1, p2]);
		const preLoan = preLoanRes.requirementsCategories.map((item, index) => ({
			...item,
			metadata: { ...requirementsCategoriesMetadata[index], index }
		}));

		commit(SET_LOAN_APPLICATION_MERITS, {
			preLoanActivation: preLoanActivationRes.requirementsCategories,
			preLoan
		});
	},
	async [GET_LOAN_APPLICATION]({ commit }) {
		const res = await services.ApiServiceCredits.post(
			'/loan-application/getLast',
			{ process: _PROCESS }
		);
		let { success, message, application } = res.payload;

		application = application || {};
		const { amountApproved, maxAmountApproved, policy } = application;

		if (policy) {
			const { maxAmount, minAmount } = policy;
			policy.maxAmount = numberFromMoneyFormat(maxAmount);
			policy.minAmount = numberFromMoneyFormat(minAmount);
		}

		commit(SET_LOAN_APPLICATION, {
			...application,
			currentStep: application.currentStep || 'P',
			amountApproved: amountApproved
				? numberFromMoneyFormat(amountApproved)
				: null,
			maxAmountApproved: maxAmountApproved
				? numberFromMoneyFormat(maxAmountApproved)
				: null,
			policy,
			success,
			message
		});
	},
	async [UPDATE_LOAN_APPLICATION]({ state, commit }, payload) {
		const data = state.loanApplication;
		const { id } = data;

		await services.ApiServiceCredits.put(`/loan-application/update/${id}`, {
			...data,
			...payload
		});
		commit(SET_LOAN_APPLICATION, { ...data, ...payload });
	},
	async [APPLY_LOAN_POLICY]({ state, commit, dispatch }) {
		const { id, policy } = state.loanApplication;

		const res = await services.ApiServiceCredits.post(
			'/loan-application/execute-engine',
			{
				applicationId: id,
				policyId: policy.id,
				process: _PROCESS
			}
		);

		const { success, message, engineResult } = res.payload;

		if (!success) {
			commit(SET_LOAN_APPLICATION, {
				...state.loanApplication,
				policyStatus: 'R',
				currentStep: 'P',
				success,
				message
			});
			throw TypeError(message);
		}

		await dispatch(UPDATE_LOAN_APPLICATION, {
			policyStatus: success ? 'A' : 'R',
			amountApproved: engineResult.amount,
			currentStep: success ? 'A' : 'P',
			success,
			message
		});
	},
	async [LOAN_SEND_OTP]({ commit, state }, cellphone) {
		await services.ApiServiceAuthorizer.post('/util/send-otp', {
			cellphone,
			process: _PROCESS
		});
	},
	async [LOAN_VALIDATE_OTP]({ commit, state }, { code, cellphone }) {
		const res = await services.ApiServiceCredits.post(
			'/loan-application/validate-otp',
			{
				cellphone,
				code,
				process: _PROCESS,
				applicationId: state.loanApplication.id
			}
		);

		if (!res.payload.success) {
			throw TypeError('Verifica el código enviado e ingresalo nuevamente');
		}
		commit(SET_LOAN_APPLICATION, {
			...state.loanApplication,
			otpVerification: true
		});
		return 'Success';
	},
	async [GET_PRE_LOAN_DATA]({ commit, state, dispatch }, data) {
		const raw = {
			applicationId: state.loanApplication.id,
			documentType: 1,
			documentNumber: `${data.documentNumber}`,
			lastName: data.lastNames,
			preApproved: state.loanApplication.amountApproved
		};

		const preLoanData = await services.ApiServiceCredits.post(
			'/credit/getPreLoanData',
			raw
		);

		const { payload, success, message } = preLoanData;

		if (!success) {
			commit(SET_LOAN_APPLICATION, {
				...state.loanApplication,
				success,
				message: message || payload.message
			});
			return;
		} else {
			commit(SET_LOAN_APPLICATION, {
				...state.loanApplication,
				maxAmountApproved: payload.maxLoan,
				currentStep: 'C',
				loanUuid: payload.uuid
			});
		}

		await dispatch(UPDATE_LOAN_APPLICATION);
	},
	async [GET_LOAN_APPLICATION_DATA]({ commit, state }) {
		const { loanUuid } = state.loanApplication;
		const res = await services.ApiServiceCredits.post(
			'/credit/getLoanRequestData',
			{ uuid: loanUuid }
		);
		const { data } = res.payload;

		commit(SET_LOAN_APPLICATION_DATA, data);
		return data;
	},
	async [UPDATE_LOAN_APPLICATION_DATA](
		{ commit, state, dispatch },
		{ formData, nextStep }
	) {
		const { loanApplication } = state;
		const { loanUuid } = loanApplication;
		// Update state
		const data = { ...state.loanApplicationData, ...formData };
		commit(SET_LOAN_APPLICATION_DATA, data);

		await services.ApiServiceCredits.post('/credit/updateLoanRequest', {
			uuid: loanUuid,
			data: {
				loanValue: data.loanValue,
				timeLimitID: data.timeLimitID,
				frecuencyID: data.frecuencyID,
				// Datos personales
				firstName: data.firstName,
				surName: data.surName,
				email: data['11'],
				cellphoneNumber: data['10'],
				birthDate: data['26'],
				idIssueDate: data['25'],
				// datos de comercio
				companyName: data['6'],
				incomeMonthly: data['22'],
				expensesMonthly: data['21']
			}
		});
		await dispatch(UPDATE_LOAN_APPLICATION, { currentStep: nextStep });
	},
	async [GET_PRE_LOAN_TIME_LIMITS]() {
		return await services.ApiServiceCredits.get('/util/getTimeLimits');
	},
	async [GET_PRE_LOAN_PAYMENT_FRECUENCIES]() {
		return await services.ApiServiceCredits.get('/util/getPaymentFrequencies');
	},
	async [GET_PRE_LOAN_ESTIMATE]({ commit, state }, payload) {
		const { loanUuid } = state.loanApplication;
		const res = await services.ApiServiceCredits.post('/credit/getEstimate', {
			...payload,
			uuid: loanUuid
		});
		return res.payload;
	},
	async [GET_LOAN_SECURITY_QUESTIONS]({ state }) {
		const { loanUuid } = state.loanApplication;
		const res = await services.ApiServiceCredits.post(
			'/credit/validateIdentityQuestions',
			{
				uuid: loanUuid
			}
		);
		return res.payload;
	},
	async [VALIDATE_LOAN_SECURITY_QUESTIONS]({ state }, responses) {
		const { loanUuid } = state.loanApplication;
		return await services.ApiServiceCredits.post(
			'/credit/validateIdentityResponse',
			{
				applicationId: state.loanApplication.id,
				uuid: loanUuid,
				responses
			}
		);
	},
	async [DELETE_LOAN_APPLICATION]({ state }) {
		const { id } = state.loanApplication;
		return await services.ApiServiceCredits.delete('/loan-application/delete', {
			data: {
				applicationId: id
			}
		});
	},
	async [FINISH_LOAN_REQUEST]({ state }) {
		const { loanUuid } = state.loanApplication;
		const res = await services.ApiServiceCredits.post(
			'/credit/finishLoanRequest',
			{
				uuid: loanUuid
			}
		);
		const { success, message } = res.payload;

		if (!success) {
			throw TypeError(message);
		}

		return res;
	}
};

export default actions;
