import Vuex from 'vuex';
import Vue from 'vue';
import { CLEAR_ERRORS } from './types/actions.type';
import { SET_ERRORS } from './types/mutations.type';
import auth from './modules/auth.module';
import devices from './modules/devices.module';
import loans from './modules/loans.module';
import places from './modules/places.module';
import profiles from './modules/profiles.module';
import report from './modules/report.module';
import transaction from './modules/transaction.module';
import users from './modules/users.module';
import brands from './modules/brands.module';
import products from './modules/products.module';
import dashboard from './modules/dashboard.module';
import manage from './modules/manage.module';
import sp from './modules/sp.module';
import mo from './modules/mo.module';
import support from './modules/support.module';
import payments from './modules/payments.module';
import queries from './modules/queries.module';
import parameters from './modules/parameters.module';
import notify from './modules/notify.module';
import credits from './modules/credits';
import graphs from './modules/graph.module';
import sell from './modules/sell';
import admin from './modules/admin';
import notifications from './modules/notifications';
import merchant from './modules/merchant.module';

Vue.use(Vuex);

const state = {
	errors: {}
};

const getters = {
	errors(state) {
		return state.errors;
	}
};

const mutations = {
	[SET_ERRORS](state, errors) {
		var data = {};
		if (errors.response) data = errors.response.data.errors;
		state.errors = data;
	}
};

const actions = {
	[CLEAR_ERRORS](context) {
		context.commit(SET_ERRORS, {});
	}
};

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	modules: {
		auth,
		devices,
		dashboard,
		loans,
		notifications,
		places,
		profiles,
		report,
		transaction,
		users,
		brands,
		products,
		manage,
		sp,
		mo,
		support,
		payments,
		queries,
		parameters,
		notify,
		credits,
		graphs,
		sell,
		admin,
		merchant
	}
});

export { default as version } from './version';
export * as mutations from './types/mutations.type';
export * as actions from './types/actions.type';
export * as notifyReducer from './reducers/notify.reducer';
export * as billReducer from './reducers/bill.reducer';
export * as brandReducer from './reducers/brand.reducer';
export * as attributesReducer from './reducers/attributes.reducer';

// notifications module
export * as notificationsActions from './modules/notifications/actions.type';
export * as notificationsMutations from './modules/notifications/mutations.type';