const getters = {
    sellCategories(state) {
        return state.categories;
    },
    sellProduct(state) {
        return state.product;
    },
    sellSelectedCategoryId(state) {
        return state.selectedCategoryId;
    },
    sellShowProductsModal(state) {
        return state.showProductsModal;
    },
    updateLastTransactions(state) {
        return state.updateLastTransactions;
    }
}
export default getters;