import {
    SET_SELL_CATEGORIES,
    SET_SELL_PRODUCT,
    SET_SELL_PRODUCTS_MODAL,
    SET_SELL_SELECTED_CATEGORY_ID,
    SET_UPDATE_LAST_TRANSACTIONS
} from '../../types/mutations.type';

const mutations = {
    [SET_SELL_CATEGORIES](state, payload) {
        state.categories = payload;
    },
    [SET_SELL_PRODUCT](state, payload) {
        state.product = payload;
    },
    [SET_SELL_SELECTED_CATEGORY_ID](state, payload) {
        state.selectedCategoryId = payload;
    },
    [SET_SELL_PRODUCTS_MODAL](state, payload) {
        state.showProductsModal = payload;
    },
    [SET_UPDATE_LAST_TRANSACTIONS](state, payload) {
        state.updateLastTransactions = payload;
    }
}

export default mutations;