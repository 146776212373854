import services from '@superpagos/mf-services';
import {
  FETCH_LOANS,
  LOANS_REQUESTLOAN,
  FETCH_LOANS_ALERTS,
  LOANS_ALERTS_CREATE,
  LOANS_ALERT_DELETE,
  FETCH_AUTOLOANS,
  LOANS_AUTOLOANS_CREATE,
  LOANS_CHILDREN_REQUESTLOAN,
  LOANS_CHILDREN_SAVE_REQUESTLOAN,
  DEBTORS,
  QUOTADEBTORS,
  DEBTS,
  PAY_DEBT,
  PAYMENTS_LOAN,
  ME_DEBTS,
  LOANS_AUTOLOANS_DELETE,
} from '../types/actions.type';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [FETCH_LOANS]() {
    return services.ApiService.get('/api/v1/loans');
  },

  [LOANS_REQUESTLOAN](context, data) {
    return services.ApiService.post('/api/v1/loans', data);
  },

  [FETCH_LOANS_ALERTS]() {
    return services.ApiService.get('/api/v1/loans/listAlerts');
  },

  [LOANS_ALERTS_CREATE](context, data) {
    return services.ApiService.post('/api/v1/loans/listAlerts', data);
  },

  [LOANS_ALERT_DELETE](context, data) {
    return services.ApiService.post('/api/v1/loans/alerts/delete', data);
  },

  [FETCH_AUTOLOANS]() {
    return services.ApiService.get('/api/v1/loans/autoLoans');
  },

  [LOANS_AUTOLOANS_CREATE](context, data) {
    return services.ApiService.post('/api/v1/loans/autoLoans', data);
  },

  [LOANS_CHILDREN_REQUESTLOAN](context, filters) {
    return services.ApiService.post(
      '/api/v1/loans/listRequestChildren',
      filters
    );
  },

  [LOANS_CHILDREN_SAVE_REQUESTLOAN](context, data) {
    return services.ApiService.post('/api/v1/loans/requestChildren', data);
  },

  [DEBTORS](context, filters) {
    return services.ApiService.post('/api/v1/loans/debtors', { filters });
  },

  [QUOTADEBTORS](context, filters) {
    return services.ApiService.post('/api/v1/loans/quotaDebtors', { filters });
  },

  [DEBTS](context, { userId, status }) {
    return services.ApiService.get(
      '/api/v1/loans/debtors',
      userId + '?status=' + status
    );
  },

  [PAY_DEBT](context, data) {
    return services.ApiService.post('/api/v1/loans/debtors/pay', data);
  },

  [PAYMENTS_LOAN](context, loanId) {
    return services.ApiService.get('/api/v1/loans/payments/' + loanId);
  },

  [ME_DEBTS](context, filters) {
    return services.ApiService.post('/api/v1/loans/meDebts', { filters });
  },

  [LOANS_AUTOLOANS_DELETE](context, data) {
    return services.ApiService.post('/api/v1/loans/autoLoans/delete', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
