import services from '@superpagos/mf-services';
import {
  REPORT_PROBLEM,
  FETCH_TICKETS,
  FETCH_LOGS,
  CREATE_LOG,
  SWITCH_TICKET,
  CREATE_SUGGEST,
  FETCH_FAQS,
  FETCH_TUTORIALS,
  FETCH_ADS,
} from '../types/actions.type';
import { SET_TICKETS, SET_LOGS, SET_NEWS } from '../types/mutations.type';

const state = {
  tickets: [],
  logs: [],
  news: [],
};

const getters = {
  tickets(state) {
    return state.tickets;
  },
  logs(state) {
    return state.logs;
  },
  news(state) {
    return state.news;
  },
};

const mutations = {
  [SET_TICKETS](context, data) {
    context.tickets = data;
  },
  [SET_LOGS](context, data) {
    context.logs = data;
  },
  [SET_NEWS](context, data) {
    context.news = data;
  },
};

const actions = {
  [REPORT_PROBLEM](context, data) {
    return services.ApiService.post('/api/v1/issues/create', data);
  },
  [CREATE_LOG](context, data) {
    return services.ApiService.post('/api/v1/issues/createLog', data);
  },
  [FETCH_TICKETS](context) {
    return services.ApiService.get('/api/v1/issues/list').then((tickets) => {
      context.commit(SET_TICKETS, tickets);
      return Promise.resolve(tickets);
    });
  },
  [FETCH_LOGS](context, issueId) {
    return services.ApiService.get('/api/v1/issues/logs/' + issueId).then(
      (logs) => {
        context.commit(SET_LOGS, logs);
        return Promise.resolve(logs);
      }
    );
  },
  [SWITCH_TICKET](context, issueId) {
    return services.ApiService.post('/api/v1/issues/switch/' + issueId);
  },
  [CREATE_SUGGEST](context, data) {
    return services.ApiService.post('/api/v1/issues/suggest', data);
  },
  [FETCH_FAQS]() {
    return services.ApiService.get('/api/v1/issues/faq');
  },
  [FETCH_TUTORIALS]() {
    return services.ApiService.get('/issues/tutorials');
  },
  [FETCH_ADS]() {
    return services.ApiService.get('/issues/ads');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
