import services from '@superpagos/mf-services';
import {
  FETCH_PROFILES,
  PROFILES_CREATE,
  PROFILES_CHANGE,
} from '../types/actions.type';
import { SET_USER } from '../types/mutations.type';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [FETCH_PROFILES](context) {
    return new Promise((resolve, reject) => {
      services.ApiService.get('/api/v1/profiles')
        .then((result) => {
          resolve();
          context.commit(SET_USER, result.user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [PROFILES_CREATE](context, data) {
    return new Promise((resolve, reject) => {
      services.ApiService.post('/api/v1/profiles', data)
        .then((result) => {
          resolve();
          context.commit(SET_USER, result.user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [PROFILES_CHANGE](context, data) {
    return new Promise((resolve, reject) => {
      services.ApiService.post('/api/v1/profiles/change', data)
        .then((result) => {
          resolve();
          context.commit(SET_USER, result.user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
