const getters = {
	notifications: state => state.notifications,
	unreadNotifications: state =>
		state.notifications.filter(notification => !notification.read),
	unreadNotificationsCount: (state, getters) =>
		getters.unreadNotifications.length,
	todayNotifications: state =>
		state.notifications.filter(notification => {
			const today = new Date();
			const createdAt = new Date(notification.createdAt);
			return today.toDateString() === createdAt.toDateString();
		}),
  previousNotifications: state =>
    state.notifications.filter(notification => {
      const today = new Date();
      const createdAt = new Date(notification.createdAt);
      return today.toDateString() !== createdAt.toDateString();
    }),
};

export default getters;
