export const SET_USER = 'setUser';
export const SET_BRAND = 'updateBrand';
export const SET_LOGOUT = 'setLogout';
export const SET_PERSON_VALIDATION = 'setPersonValidation';
export const SET_BAGS = 'setBags';
export const SET_CARRIERS = 'setCarriers';
export const SET_SLIDES = 'setSlides';
export const SET_REPORT = 'setReport';
export const SET_OWN_USERS = 'setOwnUsers';
export const SET_CHILDREN = 'setChildren';
export const SET_STATES = 'setStates';
export const SET_ERRORS = 'setErrors';
export const SET_TRANSACTIONS = 'setTransactions';
export const SET_TRANSACTIONS_LAST_SELLS = 'setTransactionsLastSells';
export const SET_PACKAGES = 'setPackages';
export const SET_BANKS = 'setBanks';
export const SET_HADVICE = 'setHAdvice';
export const SET_PRODUCTS = 'setProducts';
export const SET_SNR_OFFICES = 'setSnrOffices';
export const SET_BALANCE = 'setBalance';
export const SET_HISTORY = 'setHistoryUser';
export const SET_TICKETS = 'setTickets';
export const SET_LOGS = 'setLogs';
export const SET_QUERY_FORM = 'setQueryForm';
export const SET_SELL_PARAMS = 'SET_SELL_PARAMS';
export const SET_PROCESSING = 'setProcessing';
export const SET_NEWS = 'setNews';
export const SET_LASTBUY = 'setLastBuy';
export const SET_ISACTIVE_NOTIFY = 'setIsActiveNotify';
export const SET_DATA_NOTIFY = 'setDataNotify';
export const SET_UNAUTHORIZED = 'set_unauthorized';
export const SET_BILLDATA = 'set_billdata';
export const SET_REQUIREMENTS_PROCESS = 'set_requirements_process';
export const SET_COMMISSIONS = 'setCommissions';
export const SET_LOAN_APPLICATION_MERITS = 'setLoanApplicationMerits';
export const SET_LOAN_APPLICATION = 'setLoanApplication';
export const SET_LOAN_APPLICATION_DATA = 'updateLoanApplicationData';
export const SET_GRAPH = 'setGraph';
export const SET_SELL_CATEGORIES = 'setSellCategories';
export const SET_SELL_PRODUCT = 'setSellProduct';
export const SET_SELL_PRODUCTS_MODAL = 'setSellProductsModal';
export const SET_SELL_SELECTED_CATEGORY_ID = 'setSellSelectedCategoryId';
export const SET_UPDATE_LAST_TRANSACTIONS = 'setUpdateLastTransactions';
export const SET_SELL_SELECTED_CATEGORY = 'setSellSelectedCategory';
export const SET_SELL_CATEGORY = 'setSellCategory';
export const SET_PRODUCT_RESOURCE = 'setProductResource';
export const SET_REFERRED_VALUE = 'setReferredValue';
export const SET_GLOBAL_VERSION = 'setGlobalVersion';
export const SET_APP_UPDATE = 'setAppUpdate';
export const SET_STEP_BNPL = 'setStepBnpl';
export const SET_AMOUNT_APPROVED = 'setAmountApproved';
export const SET_SELECTED_ACCOUNT = 'setSelectedAccound';
export const SET_LOAN_RESUME = 'setLoanResume';
export const SET_REFERENCE = 'setReference';
export const SET_AUTH_CODE = 'setAuthCode';
export const SET_BNPL_REQUIREMENTS = 'setBnplRequirements';
export const SET_VALID_LESS_LOAN = 'setValidLessLoan';
export const SET_BEST_SELLING_BILLS = 'setBestSellingBills';
export const SET_REFERENCE_SUGGESTION = 'setReferenceSuggestion';
export const SET_VERSION_PDF = 'setVersionPdf';
export const SET_REQUEST_ATTRIBUTES_PAYLOAD = 'setRequestAttributesPayload';
export const SET_BRAND_PAYLINK = 'setBrandPaylink';
