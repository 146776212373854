import services from '@superpagos/mf-services';
import {
  UPDATE_USER,
  SHOW_USER,
  USER_AUTOREVERSE_SWITCH,
  USER_TOGGLE_BLOCKED,
  USER_AUTOREVERSE_LIST,
  FETCH_USER_CHILDREN,
  USER_CHILDREN_CREATE,
  DELETE_USER,
  FETCH_OWN_USER,
  USER_RESEND_PASSWORD,
  USER_RESEND_COORDINATES,
  SEARCH_USERS,
  FETCH_CUSTOMERS_BALANCE,
  FETCH_HIER,
  HISTORY_USER,
  UPDATE_USER_DATA,
  ADD_CELLPHONE,
  ACCEPT_TERMS,
  GET_COMMISSIONS_GROUPS,
  GET_COMMISSIONS_PRODUCTS,
  CREATE_COMMISSIONS_GROUPS,
  ASSIGN_COMMISSION_GROUP, FETCH_PERSON_VALIDATION,
} from '../types/actions.type';
import {
  SET_OWN_USERS,
  SET_CHILDREN,
  SET_HISTORY, SET_PERSON_VALIDATION,
} from '../types/mutations.type';

const state = {
  ownUsers: [],
  children: [],
  historyUser: [],
  personValidation: null
};

const getters = {
  ownUsers(state) {
    return state.ownUsers;
  },
  children(state) {
    return state.children;
  },
  historyUser(state) {
    return state.historyUser;
  },
  personValidation(state) {
    return state.personValidation;
  }
};

const mutations = {
  [SET_OWN_USERS](context, users) {
    context.ownUsers = users;
  },
  [SET_CHILDREN](context, children) {
    context.children = children;
  },
  [SET_HISTORY](context, historyUser) {
    context.historyUser = historyUser;
  },
  [SET_PERSON_VALIDATION](state, payload) {
    state.personValidation = payload;
  },
};

const actions = {
  [SHOW_USER](context, userId) {
    return services.ApiService.get('/api/v1/users/' + userId);
  },

  [UPDATE_USER](context, data) {
    return services.ApiService.post('/api/v1/users/' + data.id, data);
  },

  [HISTORY_USER](context, userId) {
    return services.ApiService.get('/api/v1/users/history/' + userId);
  },

  [USER_AUTOREVERSE_SWITCH](context, data) {
    return services.ApiService.post('/api/v1/users/autoreverse/switch', data);
  },

  [USER_TOGGLE_BLOCKED](context, data) {
    return services.ApiService.post('/api/v1/users/toggleBlockedUser', data);
  },

  [USER_AUTOREVERSE_LIST]() {
    return services.ApiService.get('/api/v1/users/autoreverse/list');
  },

  [FETCH_USER_CHILDREN](context, { force, userExt }) {
    var children = context.getters.children;
    if (children.length > 0 && !force) return Promise.resolve(children);
    return services.ApiService.get(
      '/user/children',
      `?userExt=${userExt ? userExt : ''}`
    ).then((children) => {
      context.commit(SET_CHILDREN, children);
      return Promise.resolve(children);
    });
  },

  [FETCH_OWN_USER](context, { force, userExt }) {
    var users = context.getters.ownUsers;
    if (users.length > 0 && !force) return Promise.resolve(users);

    return services.ApiService.get(
      '/user/children',
      `?selfuser=1&userExt=${userExt ? userExt : ''}`
    ).then((users) => {
      context.commit(SET_OWN_USERS, users);
      return Promise.resolve(users);
    });
  },

  [USER_CHILDREN_CREATE](context, data) {
    return services.ApiService.post('/user/children', data);
  },

  [DELETE_USER](context, data) {
    return services.ApiService.post('/api/v1/users/delete', data);
  },

  [USER_RESEND_PASSWORD](context, data) {
    return services.ApiService.post('/api/v1/users/resend/password', data);
  },

  [USER_RESEND_COORDINATES](context, data) {
    return services.ApiService.post('/api/v1/users/resend/coordinates', data);
  },

  [SEARCH_USERS](context, search) {
    return services.ApiService.post('/api/v1/users/search', { search });
  },

  [FETCH_CUSTOMERS_BALANCE](context, data) {
    return services.ApiService.post('/api/v1/users/customersBalance', data);
  },

  [FETCH_HIER](context, filters) {
    var userId = filters.userId ? filters.userId : '';
    var recaudoId = filters.recaudoId ? filters.recaudoId : '';
    return services.ApiService.get(
      `/api/v1/users/hier`,
      `?userId=${userId}&recaudoId=${recaudoId}`
    );
  },

  [UPDATE_USER_DATA](context, data) {
    return services.ApiService.post('/api/v1/users/' + data.id, data);
  },

  [ADD_CELLPHONE](context, data) {
    return services.ApiService.post('/user/cellphone/verify', data);
  },
  [ACCEPT_TERMS](context, data) {
    return services.ApiService.post('/api/v1/users/data/update', data);
  },
  [GET_COMMISSIONS_GROUPS](context, data) {
    return services.ApiService.post('/api/v2/commissions/list', data);
  },
  [GET_COMMISSIONS_PRODUCTS](context, data) {
    return services.ApiService.post('/api/v2/commissions/list/products', data);
  },
  [CREATE_COMMISSIONS_GROUPS](context, data) {
    return services.ApiService.post('/api/v2/commissions/create', data);
  },
  [ASSIGN_COMMISSION_GROUP](context, data) {
    return services.ApiService.post('/api/v2/commisions/change', data);
  },
  async [FETCH_PERSON_VALIDATION]({ commit }) {
    try {
      const res = await services.ApiService.get('/user/identity-validation/get-last');
      const { success, message, payload } = res;

      if (!success) {
        throw TypeError(message);
      }

      commit(SET_PERSON_VALIDATION, payload);
      return res;
    } catch (e) {
      throw e;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
