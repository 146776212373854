import { ACTIVE_NOTIFY, INACTIVE_NOTIFY } from '../types/actions.type';

export const activeNotify = {
  success: (store, state) => {
    let data = {
      message: state,
      type: 'success',
    };
    store.dispatch(ACTIVE_NOTIFY, data);
  },
  error: (store, state) => {
    let data = {
      message: state,
      type: 'error',
    };
    store.dispatch(ACTIVE_NOTIFY, data);
  },
};
export const inactiveNotify = (store) => {
  store.dispatch(INACTIVE_NOTIFY);
};
