import { SET_GRAPH } from '../types/mutations.type';

const state = {
  graphs: [],
};

const getters = {
  graphs(state) {
    return state.graphs;
  },
};

const mutations = {
  [SET_GRAPH](context, data) {
    context.graphs = data;
  },
};

export default {
  state,
  getters,
  mutations,
};
