const requirementsCategoriesMetadata = {
    '0': {
        header: {
            t1: 'Regálanos algunos datos',
            t2: 'Tus datos personales',
            t3: 'Solo falta finalizar con unos simples pasos y disfrutar de tu cupo'
        },
        stepId: 'DP',
        nextStep: 'DC'
    },
    '1': {
        header: {
            t1: 'Regálanos algunos datos',
            t2: 'Información de tu comercio',
            t3: 'Los datos que debes llenar en esta sección son únicamente los datos de tu comercio'
        },
        stepId: 'DC',
        nextStep: 'IF'
    },
    '4': {
        header: {
            t1: 'Regálanos algunos datos',
            t2: 'Información financiera',
            t3: 'Los datos que debes llenar en esta sección son únicamente tus datos financieros, no le contamos a nadie'
        },
        stepId: 'IF',
        nextStep: 'S'
    },
    '3': {
        header: {
            t1: 'Sabemos que eres tu, pero por si acaso',
            t2: 'Preguntas de seguridad',
            t3: 'Estas preguntas son importantes para validar tu identidad'
        },
        stepId: 'S',
        nextStep: 'F'
    },
    '2': {
        header: {
            t1: 'Último paso',
            t2: 'Firmas',
            t3: 'Por favor has clic en el botón para firmar la documentación necesaria para finalizar el proceso'
        },
        stepId: 'F'
    },
}

const numberFromMoneyFormat = (input) => {
    return Number(input.replace(/[^0-9.-]+/g, ''))
}

export { requirementsCategoriesMetadata, numberFromMoneyFormat }

