
// const stateDefault = {
//     notifications: [
//         {
//             "title": "Recarga pendiente",
//             "message": "Recarga pendiente",
//             "extra": ['03/05/2024','21:30'],
//             "action": "viewTransactionResume",
//             "type": "balanceCharge",
//             "createdAt": "2024-05-06T21:30:13.486Z",
//             "expiresAt": "2024-05-05T21:30:13.486Z",
//             "meta": {
//                 "reference": "532c59c0-0994-11ef-8682-4d15aa2d0c36",
//                 "status": "pending",
//             },
//             "hash": "b52185d412ec965d8f93d8f825b84168c3e1ca32ccc65bcbb8a4913c356a1f08"
//         },
//         {
//             "title": "Pago pendiente",
//             "message": "Recarga pendiente",
//             "extra": ['03/05/2024','21:30'],
//             "action": "view",
//             "type": "balanceCharge",
//             "createdAt": "2024-06-03T21:30:13.486Z",
//             "expiresAt": "2024-05-05T21:30:13.486Z",
//             "meta": {
//                 "reference": "532c59c0-0994-11ef-8682-4d15aa2d0c36",
//                 "status": "approved"
//             },
//             read: true,
//             "hash": "b52185d412ec965d8f93d8f825b84168c3e1ca32ccc65bcbb8a4913c356a1f08"
//         }
//     ]
// };


// window.localStorage.setItem('notifications', JSON.stringify(stateDefault));
let state = JSON.parse( window.localStorage.getItem('notifications')) || {notifications: []};


export default state;
