export const CLEAR_ERRORS = 'clearErrors';
/////////////AUTH////////////

export const CHECK = 'check';
export const CHECK_USERNAME = 'checkUsername';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const VALIDATE_TOKEN_ACTIVE = 'validateTokenActive';
export const CHANGE_PASSWORD = 'changePassword';
export const RECOVER_PASSWORD = 'recoverPassword';
export const SESSIONS = 'sessions';
export const CLOSE_SESSION = 'closeSession';
export const CLOSE_ALL_SESSION = 'closeAllSession';
export const CLOSE_SESSION_AUTH = 'closeAllSessionAuth';
export const FETCH_PRIVILEGES = 'fetchPrivileges';
export const UPLOAD_DATA = 'uploadData';
export const ACCEPT_TERMS = 'acceptTerms';
export const CHANGE_3FA = 'change3FA';
export const DOCUMENTS = 'documents';
export const VALIDATE_PIN = 'validatePin';
export const RESEND_PIN = 'resendPin';
export const UNAUTHORIZED = 'unauthorized';
export const REQUEST_ATTRIBUTES = 'requestAttributes';

// Deprecated
export const REGISTER = 'register';
export const GENERATE_2FA_TOKEN = 'fetch2faToken';
export const VERIFY_2FA_TOKEN = 'verify2faToken';
export const FORGOT_PASSWORD = 'forgotPassword';
export const SEND_OTP_FORGOT = "sendOtpForgot";
export const VALIDATE_OTP_FORGOT = "validateOtpForgot";
export const REAL_TIME = 'realTime';
// Deprecated
export const GENERATE_CARNET = 'generateCarnet';
export const GENERATE_COORDINATES = 'generateCoordinates';

/////////REPORT/////////////

export const FETCH_REPORT_FORM = 'fetchReportForm';
export const FETCH_REPORT_GENERATE = 'fetchReportGenerate';
export const FETCH_CONSUMED = 'fetchConsumed';

/////////NOTIFICATIONS///////////

export const FETCH_NOTIFICATION_READ = 'fetchNotificationsRead';
export const FETCH_NOTIFICATION_UNREAD = 'fetchNotificationsUnread';
export const NOTIFICATIONS_MARK_AS_READ = 'notificacionMarkAsRead';
export const NOTIFICATIONS_CREATE = 'notificacionCreate';
export const READ_ALL = 'readAll';

////////////PLACES///////////////

export const FETCH_CITIES = 'fetchCities';
export const FETCH_STATES = 'fetchStates’';
export const GET_PAY_POINTS = 'getPayPoints';
export const FETCH_ALL_CITIES = 'fetchAllCities';

////////////PROFILES///////////////

export const FETCH_PROFILES = 'fetchProfiles’';
export const PROFILES_CREATE = 'profilesCreate';
export const PROFILES_CHANGE = 'profilesChange';

////////////USERS///////////////

export const SHOW_USER = 'showUser';
export const UPDATE_USER = 'updateUser';
export const HISTORY_USER = 'historyUser';
export const USER_AUTOREVERSE_SWITCH = 'usersAutoreverseSwitch’';
export const USER_TOGGLE_BLOCKED = 'usersToggleBlocked’';
export const USER_AUTOREVERSE_LIST = 'usersAutoreverseList';
export const FETCH_OWN_USER = 'fetchOwnUser';
export const FETCH_USER_CHILDREN = 'fetchChildren';
export const USER_CHILDREN_CREATE = 'childrenCreate';
export const DELETE_USER = 'deleteUser';
export const USER_RESEND_PASSWORD = 'userResendPass';
export const USER_RESEND_COORDINATES = 'userResendCoordinates';
export const SEARCH_USERS = 'searchUsers';
export const FETCH_CUSTOMERS_BALANCE = 'fetchCustomersBalance';
export const FETCH_HIER = 'fetchHier';
export const UPDATE_USER_DATA = 'updateUser';
export const ADD_CELLPHONE = 'addCellphone';
export const GET_COMMISSIONS = 'getCommissions';
export const GET_COMMISSIONS_GROUPS = 'getCommissionsGroups';
export const GET_COMMISSIONS_PRODUCTS = 'getCommissionsProducs';
export const CREATE_COMMISSIONS_GROUPS = 'createCommissionsGroups';
export const ASSIGN_COMMISSION_GROUP = 'assignCommissionGroup';
export const FETCH_PERSON_VALIDATION = 'fetchPersonValidation';
export const FETCH_REFERRED_VALUE = 'fetchReferredValue';

////////////TRANSACTION///////////////

export const TRANSACTION_MOVE_BALANCE = 'transactionMoveBalance';
export const TRANSACTION_MOVE_TMPBALANCE = 'transactionTmpMoveBalance';

export const TRANSACTION_TOPUP = 'transactionRecharge';
export const TRANSACTION_SELL = 'transactionSell';
export const TRANSACTION_PACKAGE = 'transactionPackage';
export const FETCH_BANKS = 'fetchBanks';
export const CARRIERS = 'fetchCarriers';
export const FETCH_TRANSACTIONS = 'fetchTransactions';
export const FETCH_TRANSACTIONS_LAST_SELLS = 'fetchTransactionsLastSells';
export const FETCH_LAST_TRANSACTIONS_BY_PRODUCT = 'fetchLastTransactionsByProduct';
export const TRANSFER_SAVE = 'transferSave';
export const STATEMENT_REPORT = 'statementReport';
export const STATEMENT_REPORT_SPECIAL = 'statementReportS';

export const ADMIN_FETCH_STATEMENTS = 'adminStatementsList';
export const SUBMIT_MANUAL_FILE_STATEMENT = 'submitManualFileStatement';
export const TRANSFER_BETWEEN_CUSTOMERS = 'transferBetweenCustomers';
export const PACKAGES = 'fetchPackages';
export const PROVIDER_APPLY_TRANSACTION = 'provider_apply_transaction';
export const REVERSE_BALANCE_ACTION = 'reverseBalance';
export const TRANSACTION_PSE = 'transactionPse';
export const FETCH_BANKS_PSE = 'fetchBanksPse';
export const FETCH_ADMIN_BANKS = 'fetchAdminBanks';
export const SAVE_BILLDATA = 'saveBilldata';

export const FETCH_BANKS_TRANSFIYA = 'fetchBanksTransfiya';

////////////// LOANS ////////////////////

export const FETCH_LOANS = 'fetchLoans';
export const LOANS_AUTHCHECK = 'authCheck';
export const FETCH_LOANS_ALERTS = 'fetchLoansAlerts';
export const LOANS_ALERTS_CREATE = 'loansAlertsCreate';
export const LOANS_ALERT_DELETE = 'LOANS_ALERT_DELETE';
export const FETCH_AUTOLOANS = 'fetchAutoloans';
export const LOANS_AUTOLOANS_CREATE = 'loansAutoloansCreate';
export const LOANS_REQUESTLOAN = 'requestLoan';
export const LOANS_CHILDREN_REQUESTLOAN = 'requestChildrenLoan';
export const LOANS_CHILDREN_SAVE_REQUESTLOAN = 'requestSaveChildrenLoan';
export const DEBTORS = 'debtors';
export const QUOTADEBTORS = 'quotaDebtors';
export const DEBTS = 'fetchDebts';
export const PAY_DEBT = 'payDebt';
export const PAYMENTS_LOAN = 'paymentsLoan';
export const ME_DEBTS = 'meDebts';
export const LOANS_AUTOLOANS_DELETE = 'deleteAutoloan';

////////////// BRANDS ////////////////////
export const BRAND_SAVE = 'brandSave';
export const FETCH_SLIDES = 'fetchSlides';
export const GET_SLIDES = 'getSlides';
export const ADD_SLIDE = 'addSlide';
export const DELETE_SLIDE = 'deleteSlide';
export const GET_BRAND = 'getBrand';
export const FETCH_SERVICE_CARRIERS = 'fetchServiceCarriers';
export const UPDATE_GLOBAL_VERSION = 'updateGlobalVersion';
export const NEED_APP_UPDATE = 'needAppUpdate';

////////////// PROVIDERS ////////////////////

export const FETCH_SERVICE_PROVIDERS = 'fetchServiceProviders';
export const CHANGE_SERVICE_PROVIDERS = 'changeServiceProviders';
export const ADD_SERVICE_PROVIDER_TRANSACTION = 'addServiceProviderTx';
export const DOWNLOAD_TX = 'downloadTx';
export const DOWNLOAD_USERS = 'downloadUsers';
export const DOWNLOAD_SALES = 'downloadSales';
export const DOWNLOAD_SALES_DETAIL = 'DOWNLOAD_SALES_DETAIL';
export const DOWNLOAD_SOAT_DETAIL = 'DOWNLOAD_SOAT_DETAIL';
export const TOGGLE_ACTIVE_CARRIER = 'toggleActiveCarrier';
export const PACKAGES_MANAGE = 'packagesManage';
export const UPDATE_PRODUCT = 'updateProduct';
export const LAST_SALES = 'lastSales';
export const FETCH_PROVIDERS_CONCEPTS = 'fetchProvidersConcepts';

////////////// PRODUCTS ////////////////////
export const FETCH_PRODUCTS = 'fetchProducts';
export const SAVE_PRODUCTS = 'saveProducts';
export const SELL_PRODUCTS = 'sellProducts';
export const FETCH_SNR_OFFICES = 'snrOffices';
export const QUERY_PRODUCTS = 'queryProduct';
export const SELL_PRODUCTS_SISTECREDITO = 'sellProductsSitecredito';
export const GET_BILL_USER = 'getBillUser';
export const PRODUCT_REQUEST_FORM = 'productRequestForm';
export const ACCEPT_PRODUCT_TERMS = 'acceptProductTerms';
export const GET_PRODUCT_LOAN_RULES = 'getProductLoanRules';
export const SAVE_PRODUCT_LOAN_RULES = 'saveProductLoanRules';
export const SEARCH_BILLS = 'searchBills';

////////////// PARAMETERS ////////////////////
export const SOAT_PARAMETERS = 'soatParameters';

export const GET_PRODUCT_PARAMETERS = 'getProductParameters';
export const UPDATE_PRODUCT_PARAMETERS = 'updateProductParameters';
export const UPLOAD_PRODUCT_FILES = 'uploadProductFiles';
export const SIGN_CONTRACT = 'signContract';
export const VALIDATE_SIGN = 'validateSign';

///////////// DEVICES ///////////////////
export const DEVICE_UNASIGN = 'unassignDevice';
export const DEVICE_LIST = 'deviceList';
export const DEVICE_CREATE = 'deviceCreate';
export const DEVICE_ASSIGN = 'deviceAssign';
export const DEVICE_UNASSIGN = 'deviceUnassign';
export const CONFIRMED_DEVICE = 'confirmedDevice';
export const VALIDATE_PASSWORD_EXPIRATION = 'passwordChangeDate';
export const CONFIRM_DEVICE = 'confirmDevice';
export const DEVICE_SEND_PIN = 'deviceSendPin';
export const DEVICE_CHECK_CONN = 'deviceCheckConn';

/////////// DASHBOARD /////////////////
export const DASHBOARD_DATA = 'dashboardData';
export const DASHBOARD_CHILDREN = 'dashboardData';

/////////// MO //////////////////////////////////
export const NEED_UPDATE_DATA = 'needUpdateData';
export const UPDATE_USER_MO = 'updateUserMo';
export const REQUEST_LOAN_MO = 'requestLoanMo';

/////////// SUPPORT /////////////////////////////
export const FETCH_TICKETS = 'fetchTickets';
export const REPORT_PROBLEM = 'reportProblem';
export const FETCH_LOGS = 'fetchLogs';
export const CREATE_LOG = 'createLog';
export const SWITCH_TICKET = 'switchTicket';
export const CREATE_SUGGEST = 'createSuggest';
export const FETCH_FAQS = 'fetchFaqs';
export const FETCH_TUTORIALS = 'fetchTutorials';
export const FETCH_ADS = 'fetchAds';

/////////////PAYMENTS////////////////////////
export const PAYMENT_CREATE = 'paymentCreate';
export const FETCH_PAYMENTS = 'fetchPayments';
export const GET_TRANSACTIONS = 'getTransactions';
export const GET_RESUME_NO_AUTH = 'getResumeNoAuth';
export const GENERATE_PAYMENT_RESOURCE = 'generatePaymentResource';
export const GENERATE_PAYMENT_RESOURCE_CASH_FACTORY =
	'generatePaymentResourceCashFactory';
export const GENERATE_PAYMENT_RESOURCE_NO_AUTH =
	'generatePaymentResourceNoAuth';
export const PAYMENT_GET_FEATURES = 'paymentGetFeatures';
export const PAYMENT_GET_FEATURES_NO_AUTH = 'paymentGetFeaturesNoAuth';
export const GET_PRODUCTS_NO_AUTH = 'getProductsNoAuth';
export const PAYMENT_STATUS = 'paymentStatus';
export const PAYMENT_CONFIRM = 'paymentConfirm';
export const GET_TERMS_BNPL	 = 'getTermsBnpl';
export const ACCEPT_TERMS_BNPL	 = 'acceptTermsBnpl';
export const GET_SESSION_INTENTION	 = 'getSessionIntention';
export const SET_CHAGE_ACCOUNT	 = 'setChageAccount';
export const SET_PROVIDE_AUTHENTICATION	 = 'setProvideAuthentication';
export const SET_CANCEL_TRANSACCION	 = 'setCancelTransaccion';
export const GET_RECEIPT = 'getReceipt';
export const SHARE_WITH_EMAIL = 'sharedWithEmail';
export const SHARE_WITH_WHATSAPP = 'sharedWithWhatsapp';
export const GET_PUBLIC_TRANSACTIONS_RECEIPT = 'getPublicTransactionsReceipt';
export const GET_BNPL_VALIDATION_QUOTA = 'getBnplValidationQuota';

///////////// PAY QR ////////////////////////
export const GET_PRODUCTS_QR_NO_AUTH = 'getProductsQrNoAuth';
export const GET_RESUME_QR_NO_AUTH = 'getResumeQrNoAuth';
export const PAYMENT_GET_FEATURES_QR = 'paymentGetFeaturesQr';
export const PAYMENT_GET_FEATURES_QR_NO_AUTH = 'paymentGetFeaturesQrNoAuth';
export const GENERATE_PAYMENT_QR_RESOURCE_NO_AUTH =
'generatePaymentQrResourceNoAuth';
export const REQUEST_QR_INTEROPERABLE_FORM = 'requestQrInteroperableForm';

///////////// QUERIES ////////////////////////////
export const FETCH_QUERIES = 'fetchQueries';
export const FETCH_QUERY_FORM = 'fetchQueryForm';
export const GENERATE_QUERY = 'generateQuery';

//////////// CUSTOMERS PAYMENT //////////////////////
export const CUSTOMER_FETCH_PAYMENT = 'customerFetchPayments';
export const CUSTOMER_CREATE_PAYMENT = 'customerCreatePayments';
export const CUSTOMER_PSE_STATUS = 'customerPseStatus';

//////////// APP NOTIFY //////////////////////////
export const ACTIVE_NOTIFY = 'activeNotify';
export const INACTIVE_NOTIFY = 'inactiveNotify';

//////////// CREDITS //////////////////////////
export const GET_LOAN_APPLICATION_MERITS = 'getLoanApplicationMerits';
export const GET_LOAN_APPLICATION = 'getLoanApplication';
export const APPLY_LOAN_POLICY = 'applyLoanPolicy';
export const LOAN_SEND_OTP = 'loanSendOtp';
export const LOAN_VALIDATE_OTP = 'loanValidateOtp';
export const GET_PRE_LOAN_DATA = 'getPreLoanData';
export const GET_PRE_LOAN_ESTIMATE = 'getPreLoanEstimate';
export const GET_PRE_LOAN_TIME_LIMITS = 'getPreLoanTimeLimits';
export const GET_PRE_LOAN_PAYMENT_FRECUENCIES = 'getPreLoanPaymentFrecuencies';
export const GET_LOAN_APPLICATION_DATA = 'getLoanApplicationData';
export const UPDATE_LOAN_APPLICATION = 'updateLoanApplication';
export const UPDATE_LOAN_APPLICATION_DATA = 'updateLoanApplicationData';
export const GET_LOAN_SECURITY_QUESTIONS = 'getLoanSecurityQuestions';
export const VALIDATE_LOAN_SECURITY_QUESTIONS = 'validateLoanSecurityQuestions';
export const DELETE_LOAN_APPLICATION = 'deleteLoanApplication';
export const FINISH_LOAN_REQUEST = 'finishLoanRequest';

//////////// SELL //////////////////////////
export const GET_SELL_CATEGORIES = 'getSellCategories';
export const GET_SELL_SELECTED_PRODUCT = 'getSellSelectedProduct';
export const UPDATE_SELL_SELECTED_PRODUCT = 'getSellSelectedProduct';
export const SELL_SEARCH_BILLS = 'sellSearchBills';
export const SELL_MASSIVE_PRODUCT = 'sellMassiveProduct';
export const SELL_PRODUCT = 'sellProduct';
export const SELL_OPEN_PRODUCTS_MODAL = 'sellOpenProductsModal';
export const SELL_CHECK_CATEGORY_EXCLUDED = 'sellCheckCategoryExcluded';
export const VALIDATE_PRODUCT_REQUIREMENTS = 'validateProductRequirements';
export const UPDATE_LAST_TRANSACTIONS = 'updateLastTransactions';

/////////// BILLS /////////////////
export const GET_BEST_SELLING_BILLS = 'getBestSellingBills';
export const GET_REFERENCE_SUGGESTION = 'getReferenceSuggestion';

/////////// REQUEST REVERSE BALANCE /////////////////
export const GET_REQUEST_REVERSE_BALANCE = 'getRequestReverseBalance';
export const UPDATE_REQUEST_REVERSE_BALANCE = 'updateRequestReverseBalance';

///////////// ADMIN //////////////////////////
export const VERIFY_ZONE_RESTRICTION = 'verifyZoneRestriction';
export const FETCH_DOCUMENT_TYPES = 'fetchDocumentsTypes';

///////////// MERCHANT //////////////////////////
export const MERCHANT_REGISTRATION = 'merchantRegistration';
export const MERCHANT_GET_BY_USER = 'merchantGetByUser';
export const MERCHANT_VALIDATION = 'merchantValidation';
