import services from '@superpagos/mf-services';
import {
  FETCH_QUERIES,
  FETCH_QUERY_FORM,
  GENERATE_QUERY,
} from '../types/actions.type';
// import { SET_QUERY_FORM } from "../mutations.type";

const state = {
  queries: {},
};

const getters = {
  queries(state) {
    return state.queries;
  },
};

const mutations = {};

const actions = {
  [FETCH_QUERY_FORM](context, id) {
    return services.ApiService.post('/api/v1/queries/form', { id });
  },

  [GENERATE_QUERY](context, data) {
    return services.ApiService.post('/api/v1/queries/generate', data);
  },
  [FETCH_QUERIES]() {
    return services.ApiService.get('/api/v1/queries/list');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
