import Store from '../superpagos-mf-store';
import { REQUEST_ATTRIBUTES } from '../types/actions.type';

export const getAttributes = data => {
	Store.dispatch(UPDATE_GLOBAL_VERSION, data);
};

export const requestPopUp = ({ view }) => {
	let data = {
		attributes: ['popup'],
		view,	
		location: window.location.host
	};

	Store.dispatch(REQUEST_ATTRIBUTES, data);
};
