const validateField = (field) => {
    let data = [];
    if (field.products) data = field.products;
    if (field.subCategories) data = field.subCategories;

    data = Array.isArray(data) ? data : [data];
    return data;
}

const findCategoryByInternalName = ({ categories, internalNameFather }) => {
    return categories.find(
        category => category.internalName === internalNameFather
    );
}

const findProductById = (category, item) => {
    let data = [];
    let dataForFilter = validateField(category);

    dataForFilter.some(product => {
        if (product.id === item.idSub) {
            dataForFilter = this.validateField(product);
            data = dataForFilter.find(p => p.id === item.idProduct);
        }
    });

    return data;
}

const selectedProductAdapter = ({ category, inputProduct, subcategoryName, categories = [] }) => {
    let data;
    let categoryFound;
    let productList = category.subCategories || category.products
    let product = inputProduct || productList[0]

    const { subcategory, internalNameFather, id } = product
    categoryFound = findCategoryByInternalName({ categories, internalNameFather });

    if (subcategory !== undefined) {
        data = findProductById(categoryFound, product);
    } else {
        data = product;
    }

    data.selectedProduct = { id, subcategory };
    data.categoryName = subcategoryName || category.name;
    data.categoryId = category.id;
    data._categoryId = category._id || category.id;
    data.internal = {
        internalName: data.internalName,
        internalNameFather: internalNameFather || data.internalName
    };
    data.productPrices = [];

    return { product: data, categoryFound };
}

// const searchBillsAdapter = ()

export { validateField, findCategoryByInternalName, findProductById, selectedProductAdapter }