import services, { AuthService } from '@superpagos/mf-services';
import {
	FETCH_PRODUCTS,
	SAVE_PRODUCTS,
	SELL_PRODUCTS,
	FETCH_SNR_OFFICES,
	QUERY_PRODUCTS,
	SEARCH_BILLS,
	SOAT_PARAMETERS,
	SELL_PRODUCTS_SISTECREDITO,
	GET_BILL_USER,
	GET_PRODUCT_LOAN_RULES,
	SAVE_PRODUCT_LOAN_RULES,
	GET_BEST_SELLING_BILLS,
	GET_REFERENCE_SUGGESTION,
} from '../types/actions.type';
import {
	SET_PRODUCTS,
	SET_SNR_OFFICES,
	SET_SELL_PARAMS,
	SET_BEST_SELLING_BILLS,
	SET_REFERENCE_SUGGESTION
} from '../types/mutations.type';

const state = {
	products: [],
	snrOffices: [],
	sellParams: null,
	bestSellingBills: [],
	referenceSuggestion: []
};

import('@superpagos/mf-services').then(services => {
	const { AuthService } = services;

	const billsStored = AuthService?.getBestSellingBills?.();
	state.bestSellingBills = billsStored || [];
});

const getters = {
	products(state) {
		return state.products;
	},
	snrOffices(state) {
		return state.snrOffices;
	},
	sellParams(state) {
		return state.sellParams;
	},
	getBestSellingPartialBills(state) {
		const bills = state.bestSellingBills;
		const partialBills = bills.slice(0, 6);
		return partialBills;
	},
	getBestSellingRestBills(state) {
		const bills = state.bestSellingBills;
		const restBills = bills.slice(6);
		return restBills;
	},
	referenceSuggestion(state) {
		return state.referenceSuggestion;
	}
};

const mutations = {
	[SET_PRODUCTS](context, data) {
		context.products = data;
	},
	[SET_SNR_OFFICES](context, data) {
		context.snrOffices = data;
	},
	[SET_SELL_PARAMS](context, data) {
		context.sellParams = data;
	},
	[SET_BEST_SELLING_BILLS](context, data) {
		AuthService?.saveBestSellingBills?.(data);
		context.bestSellingBills = data;
	},
	[SET_REFERENCE_SUGGESTION](context, data) {
		context.referenceSuggestion = data;
	}
};

const actions = {
	[FETCH_PRODUCTS](context) {
		return services.ApiService.get('/api/v1/products/list').then(products => {
			context.commit(SET_PRODUCTS, products);
		});
	},
	[GET_PRODUCT_LOAN_RULES](context, data) {
		return services.ApiService.post('/api/v1/products/listLoanRules', data);
	},
	[SAVE_PRODUCT_LOAN_RULES](context, data) {
		return services.ApiService.post('/api/v1/products/addLoanRule', data);
	},
	[SAVE_PRODUCTS](context, data) {
		return services.ApiService.post('/api/v1/products/save', data);
	},
	[SELL_PRODUCTS](context, data) {
		return new Promise((resolve, reject) => {
			let ctx = {
				ApiService: services.ApiServiceSell,
				// ApiService: services.ApiServiceRefacilOld,
				route: '/movement/sell-core',
				data,
				resolve,
				reject
			};
			// let ctx = {
			//   ApiService: services.ApiService,
			//   route: '/api/v1/products/sell',
			//   data,
			//   resolve,
			//   reject,
			// };
			context.commit(SET_SELL_PARAMS, ctx);
		});
	},
	[FETCH_SNR_OFFICES](context) {
		return services.ApiServiceSell.get('/movement/snrOffices').then(offices => {
			context.commit(SET_SNR_OFFICES, offices);
		});
	},
	[QUERY_PRODUCTS](context, data) {
		return services.ApiServiceSell.post('/movement/query', data);
		// return services.ApiServiceRefacilOld.post('/api/v1/products/query', data);
	},
	[SEARCH_BILLS](context, data) {
		return services.ApiServiceSell.post('/movement/agreements', data);
	},
	[SOAT_PARAMETERS](context, data) {
		return services.ApiService.post('/api/v1/p/soatParameters', data);
	},
	[SELL_PRODUCTS_SISTECREDITO](context, data) {
		let promiseSell = new Promise((resolve, reject) => {
			let ctx = {
				ApiService: services.ApiService,
				route: '/api/v1/products/sell/sistecredito',
				data,
				resolve,
				reject
			};
			context.commit(SET_SELL_PARAMS, ctx);
		});
		return promiseSell;
	},
	[GET_BILL_USER](context, { saleId, userId }) {
		return services.ApiServiceSell.get(`/movement/bill/${userId}/${saleId}`);
	},
	[GET_BEST_SELLING_BILLS]({ commit }) {
		return services.ApiServiceDataAnalytics.get(`/billing-analytics`).then(
			response => {
				commit(SET_BEST_SELLING_BILLS, response);
			}
		);
	},
	[GET_REFERENCE_SUGGESTION]({ commit }, id) {
		return services.ApiServiceDataAnalytics.get(
			`/agreement-references/${id}`
		).then(response => {
			commit(SET_REFERENCE_SUGGESTION, response);
		});
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
