import {
  SET_LOAN_APPLICATION,
  SET_LOAN_APPLICATION_DATA,
  SET_LOAN_APPLICATION_MERITS
} from '../../types/mutations.type';

const mutations = {
  [SET_LOAN_APPLICATION_MERITS](state, payload) {
    state.loanMerits = payload;
  },
  [SET_LOAN_APPLICATION](state, payload) {
    state.loanApplication = payload;
  },
  [SET_LOAN_APPLICATION_DATA](state, payload) {
    state.loanApplicationData = payload;
  },
};

export default mutations;
