import services from '@superpagos/mf-services';

import {
	UPDATE_SELL_SELECTED_PRODUCT,
	SEARCH_BILLS,
	SELL_SEARCH_BILLS,
	SELL_MASSIVE_PRODUCT,
	SELL_PRODUCT,
	SELL_OPEN_PRODUCTS_MODAL,
	SELL_CHECK_CATEGORY_EXCLUDED,
	VALIDATE_PRODUCT_REQUIREMENTS,
	UPDATE_LAST_TRANSACTIONS,
	SET_SELL_SELECTED_CATEGORY
} from '../../types/actions.type';

import {
	SET_SELL_PRODUCT,
	SET_SELL_PRODUCTS_MODAL,
	SET_SELL_SELECTED_CATEGORY_ID,
	SET_UPDATE_LAST_TRANSACTIONS,
	SET_SELL_CATEGORY
} from '../../types/mutations.type';

import { selectedProductAdapter } from './utils';

class SecondPasswordRequiredError extends Error {
	constructor(message) {
		super(message);
		this.name = 'SecondPasswordRequired';
	}
}

// const kImageBaseUrl = 'https://assets.refacil.co/'
const kDefaultImage = 'facturasGenerica.png';

const categoriesExcluded = {
	120: true,
	101: true,
	// deposito y retiro transfiya
	194: true,
	195: true
};

const actions = {
	[SELL_CHECK_CATEGORY_EXCLUDED]({ state }, { categoryId }) {
		return categoriesExcluded[categoryId];
	},
	[SELL_OPEN_PRODUCTS_MODAL]({ commit }, { context, categoryId }) {
		/**
		 * Verificar si la categoria esta excluida en el modal
		 * en dado caso abrir recargas (id: 2) por defecto
		 */
		if (categoryId) {
			const selectedCategoryId = !categoriesExcluded[categoryId]
				? categoryId
				: 2;
			commit(SET_SELL_SELECTED_CATEGORY_ID, selectedCategoryId);
		}

		commit(SET_SELL_PRODUCTS_MODAL, true);

		setTimeout(() => context.$bvModal.show('products'), 100);
	},
	async [UPDATE_SELL_SELECTED_PRODUCT](
		{ commit, state, dispatch },
		{ category, product, subcategoryName, categories }
	) {
		let res = selectedProductAdapter({
			category,
			inputProduct: product,
			subcategoryName,
			categories
		});

		const kSelectedCategoryId = res.categoryFound ? res.categoryFound._id : 2;
		commit(SET_SELL_SELECTED_CATEGORY_ID, kSelectedCategoryId);

		if (res.product.needValidation) {
			const response = await dispatch(VALIDATE_PRODUCT_REQUIREMENTS, {
				productId: res.product.id,
				categoryId: res.product.categoryId
			});
			res.product.requirementsValidated = response.state;
		} else {
			res.product.requirementsValidated = true;
		}

		commit(SET_SELL_PRODUCT, res.product);

		return res.product;
	},
	async [SET_SELL_SELECTED_CATEGORY](
		{ commit, dispatch, state },
		{ category }
	) {
		const sellCategory = { ...category, requirementsValidated: true };
		// validate if need validation and validate product requirements
		if (sellCategory.needValidation) {
			const response = await dispatch(VALIDATE_PRODUCT_REQUIREMENTS, {
				categoryId: sellCategory.id
			});
			sellCategory.requirementsValidated = response.state;
		}

		commit(SET_SELL_CATEGORY, sellCategory);
	},
	async [VALIDATE_PRODUCT_REQUIREMENTS]({ state }, { productId, categoryId }) {
		const endpoint = '/requirements/validateProductState';
		return await services.ApiServiceRequirement.post(endpoint, {
			productId,
			categoryId
		});
	},
	async [SELL_SEARCH_BILLS](
		{ commit, state, dispatch },
		{ input, agreementType }
	) {
		try {
			const res = await dispatch(SEARCH_BILLS, { query: input, agreementType });
			const { agreements, ownAgreements } = res;
			const _data = agreementType ? ownAgreements : agreements;
			//refactor-internalName must be in each agreement
			return _data.map(agreement => ({
				...agreement,
				key: agreement.key || agreement.productId,
				ownAgreementId: agreementType ? agreement.id : null,
				id: agreementType ? agreement.productId : agreement.id,
				internalName: 'bills',
				image: agreement.image ? `${agreement.image}` : kDefaultImage
			}));
		} catch (e) {
			throw e;
		}
	},
	async [SELL_MASSIVE_PRODUCT](
		{ state, dispatch, rootState },
		{ productList, password3FA }
	) {
		// Check user has active second-password
		const { auth } = rootState;
		const userHasActivesecondPassword = auth.user?.useSecondPassword;

		if (userHasActivesecondPassword && !password3FA) {
			throw SecondPasswordRequiredError('La segunda clave es requerida');
		}
		// Sell products
		return new Promise(async (resolve, reject) => {
			let result = [];

			try {
				for (let i = 0; i < productList.length; i++) {
					let product = {
						...productList[i],
						_preventDuplicate: true
					};
					try {
						let response = await dispatch(SELL_PRODUCT, {
							product,
							password3FA
						});
						result[i] = { value: { ...response }, status: 'fulfilled' };
					} catch (error) {
						result[i] = { status: 'rejected', value: { ...error } };
					}
				}

				return resolve(result);
			} catch (error) {
				return reject(error);
			}
		});

		/** AllSettled implementation */
		// const promises = productList.map(
		// 	async product => await dispatch(SELL_PRODUCT, { product, password3FA })
		// );
		// return Promise.allSettled(promises);
	},
	async [SELL_PRODUCT]({ state, commit }, { product, password3FA }) {
		if (password3FA) product._password = password3FA;
		// product._preventDuplicate = true;
		return await services.ApiServiceSell.post('/movement/sell-core', product);
	},
	[UPDATE_LAST_TRANSACTIONS]({ commit }) {
		commit(SET_UPDATE_LAST_TRANSACTIONS, true);
		setTimeout(() => {
			commit(SET_UPDATE_LAST_TRANSACTIONS, false);
		}, 1000);
	}
};

export default actions;
