import services from '@superpagos/mf-services';
import {
  FETCH_SERVICE_CARRIERS,
  FETCH_SERVICE_PROVIDERS,
  CHANGE_SERVICE_PROVIDERS,
  ADD_SERVICE_PROVIDER_TRANSACTION,
  DOWNLOAD_TX,
  DOWNLOAD_USERS,
  DOWNLOAD_SALES,
  DOWNLOAD_SALES_DETAIL,
  DOWNLOAD_SOAT_DETAIL,
  TOGGLE_ACTIVE_CARRIER,
  LAST_SALES,
  FETCH_ADMIN_BANKS,
  FETCH_PROVIDERS_CONCEPTS,
} from '../types/actions.type';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [FETCH_SERVICE_CARRIERS]() {
    return services.ApiService.get('/api/v1/sp/listServiceCarriers');
  },

  [FETCH_SERVICE_PROVIDERS]() {
    return services.ApiService.get('/api/v1/sp/listServiceProviders');
  },

  [CHANGE_SERVICE_PROVIDERS](context, data) {
    return services.ApiService.post('/api/v1/sp/changeServiceProviders', data);
  },

  [ADD_SERVICE_PROVIDER_TRANSACTION](context, data) {
    return services.ApiService.postMultiPart('/api/v1/sp/addProviderTx', data);
  },

  [DOWNLOAD_TX](context, data) {
    return services.ApiService.downloadPost('/api/v1/sp/downloadTx', data);
  },

  [DOWNLOAD_USERS](context, data) {
    return services.ApiService.downloadPost('/api/v1/sp/downloadUsers', data);
  },

  [DOWNLOAD_SALES](context, data) {
    return services.ApiService.downloadPost('/api/v1/sp/downloadSales', data);
  },

  [DOWNLOAD_SALES_DETAIL](context, data) {
    return services.ApiService.downloadPost(
      '/api/v1/sp/reportSalesDetail',
      data
    );
  },

  [DOWNLOAD_SOAT_DETAIL](context, data) {
    return services.ApiService.downloadPost(
      '/api/v1/sp/reportSoatDetail',
      data
    );
  },

  [TOGGLE_ACTIVE_CARRIER](context, carrierId) {
    return services.ApiService.post('/api/v1/sp/toggleCarrier', { carrierId });
  },

  [LAST_SALES](context, data) {
    return services.ApiService.post('/api/v1/sp/viewLastSales', data);
  },
  [FETCH_ADMIN_BANKS]() {
    return services.ApiService.get('/api/v1/sp/banks');
  },
  [FETCH_PROVIDERS_CONCEPTS]() {
    return services.ApiService.get('/api/v1/sp/providers/concepts');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
