const getters = {
	sellCategories(state) {
		return state.categories;
	},
	sellProduct(state) {
		return state.product;
	},
	sellCategory(state) {
		return state.sellCategory;
	},
	sellSelectedCategory(state) {
		return state.selectedCategory;
	},
	sellSelectedCategoryId(state) {
		return state.selectedCategoryId;
	},
	sellShowProductsModal(state) {
		return state.showProductsModal;
	},
	updateLastTransactions(state) {
		return state.updateLastTransactions;
	}
};
export default getters;
