import services from '@superpagos/mf-services';
import {
  NEED_UPDATE_DATA,
  UPDATE_USER_MO,
  REQUEST_LOAN_MO,
} from '../types/actions.type';
//import { } from "../mutations.type";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [NEED_UPDATE_DATA]() {
    return services.ApiService.get('/api/v1/users/needUpdate');
  },
  [UPDATE_USER_MO](context, data) {
    return services.ApiService.post('/api/v1/users/me/update', data);
  },
  [REQUEST_LOAN_MO](context, data) {
    return services.ApiService.post('/api/v1/mo/requestLoan', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
