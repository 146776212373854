const state = {
    categories: [],
    categoriesTopBar: [],
    currentCategory: [],
    product: null,
    selectedCategoryId: null,
    showProductsModal: false,
    updateLastTransactions: false
};

export default state;