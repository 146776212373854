import services from '@superpagos/mf-services';
import {
	BRAND_SAVE,
	FETCH_SLIDES,
	ADD_SLIDE,
	DELETE_SLIDE,
	GET_BRAND,
	GET_SLIDES,
	UPDATE_GLOBAL_VERSION
} from '../types/actions.type';
import { SET_SLIDES, SET_GLOBAL_VERSION } from '../types/mutations.type';

const APP_POSITIONS = {
	root: 0,
	services: 1,
	store: 2,
	components: 3,
	navbar: 4,
	sells: 5,
	pays: 6,
	reports: 7
};

const state = {
	slides: [],
	globalVersion: 'x.x.x.x.x.x.x.x' // root.services.store.components.navbar.sells.pays.reports
};

const getters = {
	slides(state) {
		return state.slides;
	},
	globalVersion(state) {
		return state.globalVersion;
	}
};

const mutations = {
	[SET_SLIDES](state, slides) {
		state.slides = slides;
	},
	[SET_GLOBAL_VERSION](state, version) {
		state.globalVersion = version;
	}
};
// const deviceKey = getHashComputer();
const actions = {
	[BRAND_SAVE](context, data) {
		return services.ApiService.postMultiPart('/api/v1/brands/save', data);
	},

	[FETCH_SLIDES](context, { force, location }) {
		var slides = context.getters.slides;
		if (slides.length > 0 && !force) return Promise.resolve(slides);
		return services.ApiService.post('/api/v1/brands/getSlides', {
			location
		}).then(slides => {
			context.commit(SET_SLIDES, slides);
		});
	},
	[GET_SLIDES](context, data) {
		return services.ApiService.post('/user/get-news-and-sliders', data).then(
			slides => {
				context.commit(SET_SLIDES, slides);
			}
		);
	},

	[ADD_SLIDE](context, data) {
		return services.ApiService.postMultiPart('/api/v1/brands/slides', data);
	},

	[DELETE_SLIDE](context, data) {
		return services.ApiService.post('/api/v1/brands/deleteSlide', data);
	},

	[GET_BRAND](context, loc) {
		return services.ApiService.post('/api/v1/brands/get', { location: loc });
	},

	[UPDATE_GLOBAL_VERSION](state, appVersionUpdate) {
		let globalVersionAux = state.getters.globalVersion.split('.');
		globalVersionAux[APP_POSITIONS[appVersionUpdate.app]] =
			appVersionUpdate.version || 'x';
		state.commit(SET_GLOBAL_VERSION, globalVersionAux.join('.'));
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
