const state = {
	categories: [],
	categoriesTopBar: [],
	currentCategory: [],
	product: null,
	selectedCategoryId: null,
	showProductsModal: false,
	updateLastTransactions: false,
	sellCategory: null,
	selectedCategory: null
};

export default state;
