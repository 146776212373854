import services from '@superpagos/mf-services';
// import {} from "../mutations.type";
import { PACKAGES_MANAGE, UPDATE_PRODUCT } from '../types/actions.type';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [PACKAGES_MANAGE](context, data) {
    return services.ApiService.post('/api/v1/manage/products', data);
  },

  [UPDATE_PRODUCT](context, data) {
    return services.ApiService.post('/api/v1/manage/update', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
