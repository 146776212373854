import services from '@superpagos/mf-services';
import {
	MERCHANT_REGISTRATION,
	MERCHANT_GET_BY_USER,
	MERCHANT_VALIDATION
} from '../types/actions.type';

const state = {};

const getters = {};

const mutations = {};

const actions = {
	[MERCHANT_REGISTRATION](context, data) {
		return services.ApiService.post('/merchant/registration', data);
	},
	[MERCHANT_GET_BY_USER](context, userId) {
		return services.ApiService.get('/merchant/users/' + userId);
	},
	[MERCHANT_VALIDATION](context, userId) {
		return services.ApiService.get('/merchant/validate/' + userId);
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
