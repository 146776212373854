import services from '@superpagos/mf-services';
import {
  FETCH_REPORT_FORM,
  FETCH_REPORT_GENERATE,
  FETCH_CONSUMED,
} from '../types/actions.type';

import { SET_REPORT } from '../types/mutations.type';

const state = {
  reports: {},
};

const getters = {
  reports(state) {
    return state.reports;
  },
};

const mutations = {
  [SET_REPORT](context, { type, report }) {
    context.reports[type] = report;
  },
};

const actions = {
  [FETCH_REPORT_FORM](context, type) {
    if (context.getters.reports[type])
      return Promise.resolve(context.getters.reports[type]);

    return services.ApiService.get(
      '/api/v1/report/form',
      '?type_report=' + type
    ).then((report) => {
      context.commit(SET_REPORT, { type, report });
      return Promise.resolve(report);
    });
  },

  [FETCH_REPORT_GENERATE](context, data) {
    return services.ApiService.post('/api/v1/report/generate', data);
  },

  [FETCH_CONSUMED](context, filters) {
    return services.ApiService.post('/api/v1/report/devices/consumed', {
      filters,
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
