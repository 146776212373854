const getters = {
  loanMerits(state) {
    return state.loanMerits;
  },
  loanApplication(state) {
    return state.loanApplication;
  },
  loanApplicationData(state) {
    return state.loanApplicationData;
  },
};

export default getters;
