import services from '@superpagos/mf-services';
import {
  DEVICE_LIST,
  DEVICE_CREATE,
  DEVICE_ASSIGN,
  DEVICE_UNASSIGN,
  CONFIRMED_DEVICE,
  CONFIRM_DEVICE,
  DEVICE_SEND_PIN,
  DEVICE_CHECK_CONN,
  VALIDATE_PASSWORD_EXPIRATION,
} from '../types/actions.type';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [DEVICE_LIST](context, data) {
    return services.ApiServiceRefacilOld.post('/api/v1/devices/list', data);
  },

  [DEVICE_CREATE](context, data) {
    return services.ApiServiceRefacilOld.post('/api/v1/devices/create', data);
  },

  [DEVICE_ASSIGN](context, data) {
    return services.ApiServiceRefacilOld.post('/api/v1/devices/assign', data);
  },

  [DEVICE_UNASSIGN](context, data) {
    return services.ApiServiceRefacilOld.post('/api/v1/devices/unassign', data);
  },
  [CONFIRMED_DEVICE](context, data) {
    return services.ApiService.post('/devices/confirmedDevice', data);
  },
  [CONFIRM_DEVICE](context, data) {
    return services.ApiService.post('/devices/confirmDevice', data);
  },
  [DEVICE_SEND_PIN](context, data) {
    return services.ApiService.post('/devices/sendPin', data);
  },
  [DEVICE_CHECK_CONN](context, data) {
    return services.ApiService.post('/devices/check/conn', data);
  },
  [VALIDATE_PASSWORD_EXPIRATION](context, data) {
    return services.ApiService.post('/auth/passwordChangeDate', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
