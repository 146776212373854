import { SET_ISACTIVE_NOTIFY, SET_DATA_NOTIFY } from '../types/mutations.type';
import { ACTIVE_NOTIFY, INACTIVE_NOTIFY } from '../types/actions.type';
const state = {
  isActiveNotify: false,
  dataNotify: {
    type: '',
    message: '',
  },
};

const getters = {
  isActiveNotify(state) {
    return state.isActiveNotify;
  },
  dataNotify(state) {
    return state.dataNotify;
  },
};

const mutations = {
  [SET_ISACTIVE_NOTIFY](context, data) {
    context.isActiveNotify = data;
  },
  [SET_DATA_NOTIFY](context, data) {
    context.dataNotify = data;
  },
};

const actions = {
  [ACTIVE_NOTIFY](context, data) {
    context.commit(SET_ISACTIVE_NOTIFY, true);
    context.commit(SET_DATA_NOTIFY, data);
  },
  [INACTIVE_NOTIFY](context) {
    context.commit(SET_ISACTIVE_NOTIFY, false);
    context.commit(SET_DATA_NOTIFY, { type: '', message: '' });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
