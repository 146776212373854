import { ADD_NOTIFICATION } from './actions.type';
import { SET_NOTIFICATIONS } from './mutations.type';

const actions = {
	[ADD_NOTIFICATION](context, notification) {
		// add notifications if the notification is not already in the list, and if exist update the notification
		// each notification has a unique hash
		const index = context.state.notifications.findIndex(
			n => n.hash === notification.hash
		);
		if (index !== -1) {
			let notificationUpdatedAux = {
				...context.state.notifications[index],
				read: false,
				...notification,
			};
			let notificationsAux = [...context.state.notifications];
			notificationsAux[index] = notificationUpdatedAux;

			context.commit(SET_NOTIFICATIONS, notificationsAux);
			return;
		}

		context.commit(SET_NOTIFICATIONS, [
			{ ...notification, read: false },
			...context.state.notifications
		]);
	}
};

export default actions;