import services from '@superpagos/mf-services';
import {
	SET_BAGS,
	SET_CARRIERS,
	SET_TRANSACTIONS,
	SET_TRANSACTIONS_LAST_SELLS,
	SET_PACKAGES,
	SET_BANKS,
	SET_PROCESSING,
	SET_LASTBUY,
	SET_BILLDATA
} from '../types/mutations.type';
import {
	TRANSACTION_MOVE_BALANCE,
	TRANSACTION_MOVE_TMPBALANCE,
	TRANSACTION_TOPUP,
	TRANSACTION_SELL,
	TRANSACTION_PACKAGE,
	CARRIERS,
	FETCH_BANKS,
	FETCH_TRANSACTIONS,
	FETCH_TRANSACTIONS_LAST_SELLS,
	FETCH_LAST_TRANSACTIONS_BY_PRODUCT,
	TRANSFER_SAVE,
	STATEMENT_REPORT,
	STATEMENT_REPORT_SPECIAL,
	SUBMIT_MANUAL_FILE_STATEMENT,
	ADMIN_FETCH_STATEMENTS,
	TRANSFER_BETWEEN_CUSTOMERS,
	PACKAGES,
	PROVIDER_APPLY_TRANSACTION,
	REVERSE_BALANCE_ACTION,
	TRANSACTION_PSE,
	FETCH_BANKS_PSE,
	SAVE_BILLDATA,
	FETCH_BANKS_TRANSFIYA
} from '../types/actions.type';

const serialize = function (dataPackages) {
	var { carriers, data, categories } = dataPackages;
	var json = {};
	for (var i = 0; i < data.length; i++) {
		if (!json[data[i].carrierId]) {
			json[data[i].carrierId] = {
				id: data[i].carrierId,
				name: carriers[data[i].carrierId].name,
				showing: false,
				options: {}
			};
		}

		if (!json[data[i].carrierId].options[data[i].categoryId]) {
			json[data[i].carrierId].options[data[i].categoryId] = {
				name: categories[data[i].categoryId],
				data: []
			};
		}

		json[data[i].carrierId].options[data[i].categoryId].data.push({
			id: data[i].id,
			price: data[i].amount,
			subTotal: data[i].subTotal,
			name: data[i].name,
			incentive: data[i].incentive,
			showing: false,
			description: data[i].meta
		});
	}

	var arr = Object.values(json);
	return arr;
};

const state = {
	packagesData: null,
	packages: [],
	bags: [],
	carriers: [],
	banks: [],
	transactions: [],
	processing: false,
	lastBuy: {},
	billData: {}
};

const getters = {
	packages(state) {
		return state.packages;
	},
	bags(state) {
		return state.bags;
	},
	carriers(state) {
		return state.carriers;
	},
	banks(state) {
		return state.banks;
	},
	transactions(state) {
		return state.transactions;
	},
	processing(state) {
		return state.processing;
	},
	lastBuy(state) {
		return state.lastBuy;
	},
	billData(state) {
		return state.billData;
	}
};

const mutations = {
	[SET_BAGS](state, bags) {
		state.bags = bags;
	},
	[SET_CARRIERS](state, carriers) {
		state.carriers = carriers;
	},
	[SET_TRANSACTIONS](state, transactions) {
		state.transactions = transactions;
	},
	[SET_PACKAGES](state, { packages, packagesData }) {
		state.packages = packages;
		state.packagesData = packagesData;
	},
	[SET_BANKS](state, banks) {
		state.banks = banks;
	},
	[SET_PROCESSING](state, processing) {
		state.processing = processing;
	},
	[SET_LASTBUY](state, lastBuy) {
		state.lastBuy = lastBuy;
	},
	[SET_BILLDATA](state, billData) {
		state.billData = billData;
	}
};

const actions = {
	[CARRIERS](context) {
		var carriers = context.getters.carriers;
		if (carriers.length > 0) return Promise.resolve(carriers);
		return services.ApiService.get('/transaction/carriers').then(carriers => {
			context.commit(SET_CARRIERS, carriers);
			return Promise.resolve(carriers);
		});
	},

	[FETCH_BANKS](context) {
		var banks = context.getters.banks;
		if (banks.length > 0) return Promise.resolve(banks);
		return services.ApiService.get('/api/v1/transaction/banks').then(banks => {
			context.commit(SET_BANKS, banks);
			return Promise.resolve(banks);
		});
	},

	[PACKAGES](context) {
		var packagesData = context.getters.packagesData;
		if (packagesData > 0) return Promise.resolve(packagesData);

		return services.ApiService.get('/api/v1/transaction/packages').then(
			data => {
				var packages = serialize(data);
				context.commit(SET_PACKAGES, { packages, packagesData: data });
				return Promise.resolve(data);
			}
		);
	},

	[TRANSACTION_MOVE_BALANCE](context, data) {
		return services.ApiService.post('/api/v1/transaction/moveBalance', data);
	},

	[TRANSACTION_MOVE_TMPBALANCE](context, data) {
		return services.ApiService.post('/api/v1/transaction/moveTmpBalance', data);
	},

	[TRANSACTION_TOPUP](context, data) {
		return new Promise((resolve, reject) => {
			let ctx = {
				ApiService: services.ApiService,
				route: '/api/v1/transaction/topup',
				data,
				resolve,
				reject
			};
			context.commit('SET_SELL_PARAMS', ctx);
		});
	},

	[TRANSACTION_SELL](context, data) {
		return services.ApiService.post('/api/v1/transaction/sell', data);
	},

	[TRANSACTION_PSE](context, data) {
		return services.ApiService.post('/api/v1/transaction/createPse', data);
	},
	[FETCH_BANKS_PSE](context, data) {
		return services.ApiService.post('/api/v2/transactions/banks/pse', data);
	},
	[TRANSACTION_PACKAGE](context, data) {
		return services.ApiService.post('/api/v1/transaction/topup', data);
	},
	[FETCH_TRANSACTIONS](context) {
		return services.ApiService.get('/transaction/list').then(transactions => {
			context.commit(SET_TRANSACTIONS, transactions);
			return Promise.resolve(transactions);
		});
	},

	[FETCH_TRANSACTIONS_LAST_SELLS](context, data) {
		return services.ApiService.post('/transaction/listSalesByDateRange', data)
			.then(transactions => {
				context.commit(SET_TRANSACTIONS_LAST_SELLS, transactions);
				return Promise.resolve(transactions);
			})
			.catch(error => {
				return Promise.reject(error);
			});
	},

	[FETCH_LAST_TRANSACTIONS_BY_PRODUCT](context, data) {
		if (!data?.productId) return;

		return services.ApiServiceSell.post('/movement/get-movement-product', data)
			.then(transactions => {
				return Promise.resolve({payload: transactions});
			})
			.catch(error => {
				return Promise.reject(error);
			});
	},

	[TRANSFER_SAVE](context, data) {
		return services.ApiService.post('/api/v1/transaction/transfer', data);
	},

	[PROVIDER_APPLY_TRANSACTION](context, data) {
		return services.ApiService.post(
			'/api/v1/transaction/provider/transaction',
			data
		);
	},

	[STATEMENT_REPORT](context, data) {
		return services.ApiService.postMultiPart(
			'/api/v1/transaction/statementReport',
			data
		);
	},
	[STATEMENT_REPORT_SPECIAL](context, data) {
		return services.ApiService.post(
			'/api/v1/transaction/statementReport/special',
			data
		);
	},

	[SUBMIT_MANUAL_FILE_STATEMENT](context, data) {
		return services.ApiService.postMultiPart(
			'/api/v1/transaction/uploadFile',
			data
		);
	},

	[ADMIN_FETCH_STATEMENTS](context, filters) {
		return services.ApiService.post(
			'/api/v1/transaction/adminStatementsList',
			filters
		);
	},

	[TRANSFER_BETWEEN_CUSTOMERS](context, data) {
		return services.ApiService.post(
			'/api/v1/transaction/transferCustomers',
			data
		);
	},

	[REVERSE_BALANCE_ACTION](context, data) {
		return services.ApiService.post('/api/v1/transaction/reverseBalance', data);
	},
	[SAVE_BILLDATA](context, data) {
		context.commit(SET_BILLDATA, data);
	},
	[FETCH_BANKS_TRANSFIYA]() {
		return services.ApiService.post('/banks/get/transfiya');
	},
};

export default {
	state,
	getters,
	mutations,
	actions
};
