import services, { AuthService } from '@superpagos/mf-services';
import {
	CHECK,
	LOGIN,
	REGISTER,
	CHANGE_PASSWORD,
	RECOVER_PASSWORD,
	LOGOUT,
	VALIDATE_TOKEN_ACTIVE,
	GENERATE_2FA_TOKEN,
	VERIFY_2FA_TOKEN,
	FORGOT_PASSWORD,
	SEND_OTP_FORGOT,
	VALIDATE_OTP_FORGOT,
	REAL_TIME,
	GENERATE_CARNET,
	SESSIONS,
	CLOSE_SESSION,
	CLOSE_ALL_SESSION,
	CLOSE_SESSION_AUTH,
	FETCH_PRIVILEGES,
	UPLOAD_DATA,
	CHANGE_3FA,
	DOCUMENTS,
	VALIDATE_PIN,
	RESEND_PIN,
	CHECK_USERNAME,
	UNAUTHORIZED,
	FETCH_REFERRED_VALUE,
	REQUEST_ATTRIBUTES
} from '../types/actions.type';
import {
	SET_USER,
	SET_BRAND,
	SET_LOGOUT,
	SET_HADVICE,
	SET_BALANCE,
	SET_UNAUTHORIZED,
	SET_REQUIREMENTS_PROCESS,
	SET_REFERRED_VALUE,
	SET_APP_UPDATE,
	SET_REQUEST_ATTRIBUTES_PAYLOAD,
} from '../types/mutations.type';
import { ENV, GLOBAL_VERSION } from '../config';

// const userStored = AuthService.getUser.() || null;
// const brandStored = AuthService.getBrand.();
const ha = window.localStorage.getItem('hAdvice');
const defaultBrand = {
	name: 'Refacil',
	primaryColor: '#00CEEE',
	secondaryColor: '#000000',
	logo: require('../assets/logo.png'),
	url: 'https://plataforma.refacil.co',
	email: 'plataforma@refacil.co',
	default: true
};
let state = {
	user: null,
	brand: defaultBrand,
	hAdvice: ha ? JSON.parse(ha) : {},
	unauthorized: {},
	processRequirements: [],
	processRequirementProductId: null,
	processRequirementCategoryId: null,
	referredValue: null,
	needAppUpdate: false,
	requestAttributesPayload: null
};

import('@superpagos/mf-services').then(services => {
	const { AuthService } = services;

	const userStored = AuthService?.getUser?.();
	state.user = userStored || null;

	const brandStored = AuthService?.getBrand?.();
	state.brand = brandStored || defaultBrand;
});

const getters = {
	globalConfig() {
		return {
			multiproduct: ENV != 'production'
		};
	},

	user(state) {
		return state.user;
	},

	sellsCategories(state) {
		return state.user?.modules.find(e => e.id === 1)?.categories || [];
	},

	brand(state) {
		return state.brand;
	},

	hAdvice(state) {
		return state.hAdvice;
	},

	unauthorized(state) {
		return state.unauthorized;
	},

	processRequirements() {
		return state.processRequirements;
	},

	processRequirementProductId() {
		return state.processRequirementProductId;
	},

	processRequirementCategoryId() {
		return state.processRequirementCategoryId;
	},

	referredValue() {
		return state.referredValue;
	},

	needAppUpdate() {
		return state.needAppUpdate;
	},

	requestAttributesPayload() {
		return state.requestAttributesPayload;
	}
};

const mutations = {
	[SET_USER](state, user) {
		state.user = user;
		AuthService.saveToken(user.token);
		AuthService.saveUser(user);
		services.ApiService.setHeader();
		//set headers for api api refacil v1/v2
		services.ApiServiceRefacilOld.setHeader();
		services.ApiServiceSell.setHeader();
		services.ApiServicePay.setHeader();
		services.ApiServiceRequirement.setHeader();
		services.ApiServiceReports.setHeader();
		services.ApiServiceDataAnalytics.setHeader();
		services.ApiServiceAdmin.setHeader();
	},

	[SET_BRAND](state, brand) {
		state.brand.id = brand.id;
		state.brand.name = brand.name;
		state.brand.email = brand.email;
		state.brand.url = brand.url;
		state.brand.primaryColor = brand.primaryColor;
		state.brand.secondaryColor = brand.secondaryColor;
		state.brand.helpYT = brand.helpYT;
		if (brand.logo) state.brand.logo = brand.logo;
		state.brand.default = false;
		state.brand.versionWeb = brand.versionWeb;

		state.brand.config = brand.config || null;
		document.title = `${brand.name} - Plataforma`;
		AuthService.saveBrand(state.brand);
	},

	[SET_LOGOUT](state) {
		AuthService.destroyToken();
		AuthService.destroyUser();
		AuthService.destroyBestSellingBills();
		state.user = null;
		window.clearInterval(window.threadRealTime);
	},
	[SET_HADVICE](state, { identificator, type }) {
		var data = state.hAdvice;
		if (!data[type]) {
			data[type] = [];
		}
		data[type].push({ identificator, date: Date.now() });
		if (data[type].length > 200) {
			data[type].slice(-100);
		}
		state.hAdvice = data;
		window.localStorage.setItem('hAdvice', JSON.stringify(data));
	},
	[SET_BALANCE](state, bags) {
		if (bags.preBalance) state.user.preBalance = bags.preBalance;
		if (bags.posBalance) state.user.posBalance = bags.posBalance;
		if (bags.tmpBalance) state.user.tmpBalance = bags.tmpBalance;
		if (bags.debt) state.user.debt = bags.debt;
	},
	[SET_UNAUTHORIZED](state, error) {
		state.unauthorized = error;
	},
	[SET_REQUIREMENTS_PROCESS](
		state,
		{ processRequirementsData, productId, categoryId = null }
	) {
		state.processRequirements = processRequirementsData;
		state.processRequirementProductId = productId || null;
		state.processRequirementCategoryId = categoryId || null;
	},
	[SET_REFERRED_VALUE](state, { value }) {
		const options = {
			style: 'currency',
			currency: 'COP',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		};
		const _currency = new Intl.NumberFormat('es-CO', options);
		// Apply formatter
		const _value =
			typeof value === 'string' ? parseFloat(value.replace(/\D+/g, '')) : value;
		state.referredValue = _currency.format(_value);
	},
	[SET_APP_UPDATE](state, needUpdate) {
		state.needAppUpdate = needUpdate;
	},
	[SET_REQUEST_ATTRIBUTES_PAYLOAD](state, payload) {
		state.requestAttributesPayload = payload;
	}
}

const actions = {
	// [CHECK](_, data) {
	//   return services.ApiService.post("/api/v2/auth/check", data).then(result => {
	//     return Promise.resolve(result);
	//   });
	// },

	// [LOGIN](context, data) {
	//   return services.ApiService.post("/api/v1/auth/login", data).then(result => {
	//     var commit = result.data;
	//     commit.saveSession = data.saveSession;
	//     context.commit(SET_USER, commit);
	//     return Promise.resolve(result.data);
	//   });
	// },

	[CHECK](_, data) {
		return services.ApiService.post('/auth/check', data).then(result => {
			return Promise.resolve(result);
		});
	},

	[CHECK_USERNAME](_, data) {
		return services.ApiService.post('/api/v1/auth/checkUsername', data).then(
			result => {
				return Promise.resolve(result);
			}
		);
	},

	[LOGIN](context, data) {
		return services.ApiService.post('/auth/login', data).then(result => {
			result.user.modules = result.modules;
			var commit = result.user;
			commit.saveSession = data.saveSession;
			context.commit(SET_USER, commit);
			return Promise.resolve(result.user);
		});
	},

	[REGISTER](context, data) {
		return services.ApiService.post('/auth/register', data).then(result => {
			context.commit(SET_USER, result.data);
			return Promise.resolve(result, data);
		});
	},

	[CHANGE_PASSWORD](context, data) {
		return services.ApiService.post('/auth/changePassword', data);
	},

	[CHANGE_3FA](context, data) {
		return services.ApiService.post('/auth/change3FA', data);
	},

	[RECOVER_PASSWORD](context, data) {
		return services.ApiService.post('/auth/recoverPassword', data);
	},

	[LOGOUT](context) {
		return services.ApiService.post('/auth/logout').then(() => {
			context.commit(SET_LOGOUT);
			return Promise.resolve();
		});
	},
	[VALIDATE_TOKEN_ACTIVE](context, token) {
		const data = { token: token };
		return services.ApiService.post('/auth/validateclosesession', data).then(
			() => {
				context.commit(SET_LOGOUT);
				return Promise.resolve();
			}
		);
	},
	[GENERATE_2FA_TOKEN]() {
		return services.ApiService.get('/auth/2fa/generate');
	},

	[VERIFY_2FA_TOKEN](context, data) {
		return services.ApiService.post('/auth/2fa/verify', data);
	},

	[FORGOT_PASSWORD](context, data) {
		return services.ApiService.post('/auth/forgot', data);
	},

	[SEND_OTP_FORGOT](context, data) {
		return services.ApiService.post('/auth/send-otp-forgot', data);
	},

	[VALIDATE_OTP_FORGOT](context, data) {
		return services.ApiService.post('/auth/validate-otp-forgot', data);
	},

	[REAL_TIME](context, attributes) {
		let data = {
			attributes,
			version: GLOBAL_VERSION,
			location: window.location.hostname,
			view: encodeURIComponent(window.location.hash.replace('#/', ''))
		};
		return services.ApiService.post('/auth/data/attributes', { data });
	},

	[GENERATE_CARNET](context, location) {
		return services.ApiService.download(
			'api/v1/users',
			'downloadCarnet?location=' + location
		);
	},

	[SESSIONS]() {
		return services.ApiService.get('/auth/sessions');
	},
	[CLOSE_SESSION](_, data) {
		return services.ApiService.post('/auth/closeSession', data);
	},
	[CLOSE_ALL_SESSION](_, data) {
		return services.ApiService.post('/auth/closeAllSession', data);
	},
	[CLOSE_SESSION_AUTH](_, data) {
		return services.ApiService.post('/auth/closeAllSessionAuth', data);
	},
	[FETCH_PRIVILEGES](context, data) {
		return services.ApiService.get('/auth/privileges', data);
	},
	[UPLOAD_DATA](context, data) {
		let ex = {
			message: 'ok'
		};
		return services.ApiService.postMultiPart(
			'/api/v1/users/uploadData',
			data,
			ex
		);
	},
	[DOCUMENTS](context, data) {
		return services.ApiService.post('/auth/documents', data);
	},
	[VALIDATE_PIN](context, data) {
		return services.ApiService.post('/auth/pin', data).then(result => {
			let user = context.getters.user;
			user.needConfirmation = 0;
			context.commit(SET_USER, user);
			return Promise.resolve(result);
		});
	},
	[RESEND_PIN](context, data) {
		return services.ApiService.post('/auth/pin/resend', data);
	},
	[UNAUTHORIZED](context, data) {
		return context.commit(SET_UNAUTHORIZED, data);
	},
	async [FETCH_REFERRED_VALUE]({ commit, state }) {
		const { referredValue } = state;
		if (referredValue) return referredValue;
		try {
			const { value } = await services.ApiService.get(
				'/auth/get-referred-value'
			);
			commit(SET_REFERRED_VALUE, { value });
		} catch (e) {
			throw e;
		}
	},
	[REQUEST_ATTRIBUTES](context, data) {
		context.commit(SET_REQUEST_ATTRIBUTES_PAYLOAD, null);
		return context.commit(SET_REQUEST_ATTRIBUTES_PAYLOAD, data);
	}
};

export default {
	state,
	getters,
	mutations,
	actions
};
