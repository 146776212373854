import services from '@superpagos/mf-services';
import {
  GET_PRODUCT_PARAMETERS,
  UPDATE_PRODUCT_PARAMETERS,
  UPLOAD_PRODUCT_FILES,
  SIGN_CONTRACT,
  VALIDATE_SIGN,
} from '../types/actions.type';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  [GET_PRODUCT_PARAMETERS](context, data) {
    return services.ApiService.post('/api/v2/parameters/fields', data);
  },
  [UPDATE_PRODUCT_PARAMETERS](context, data) {
    return services.ApiService.post('/api/v2/parameters/update', data);
  },
  [UPLOAD_PRODUCT_FILES](context, data) {
    return services.ApiService.postMultiPart('/api/v2/parameters/upload', data);
  },
  [SIGN_CONTRACT](context, data) {
    return services.ApiService.post('/api/v2/parameters/createSign', data);
  },
  [VALIDATE_SIGN](context, data) {
    return services.ApiService.post('/api/v2/parameters/validateSign', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
