import services from '@superpagos/mf-services';
import { DASHBOARD_DATA, DASHBOARD_CHILDREN } from '../types/actions.type';
import { SET_COMMISSIONS } from '../types/mutations.type';

const state = {
  commissions: {},
};

const getters = {
  commissions(state) {
    return state.commissions;
  },
};

const mutations = {
  [SET_COMMISSIONS](state, commissions) {
    state.commissions = commissions;
  },
};

const actions = {
  [DASHBOARD_DATA](context, data) {
    return services.ApiService.post('/api/v1/dashboard/get', data);
  },
  [DASHBOARD_CHILDREN](context, data) {
    return services.ApiService.post('/api/v1/dashboard/children', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
